/**
 *
 * Results for ExploreMap
 *
 *
 */

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import ButtonIcon from 'components/Elements/ButtonIcon';
import Link from 'components/Elements/Link';
import ViewIcon from 'components/icons/ViewIcon';
import { MinusIconCircleFilled } from 'components/icons/Minus';

/**
 * Results Lists for both locations on map and rispots (people)
 *
 * @param {*} { handleClick, locationData, ripspotdata }
 * @returns
 */
const Results = ({
  handleClick,
  locationData,
  ripspotdata,
  editInProgress,
}) => {
  // is/are the list(s) open
  const [isOpen, setIsOpen] = useState(false);
  // are we showing all results aka more than just 3 for either list?
  const [showAllRipspots, setShowAllRipspots] = useState(false);
  const [showAllLocations, setShowAllLocations] = useState(false);

  //
  // toggles lists open or closed
  //
  const toggle = () => setIsOpen(!isOpen);

  useEffect(() => {
    const shouldBeOpen =
      (locationData && locationData.features && locationData.features.length) ||
      (ripspotdata && ripspotdata.length);
    if (shouldBeOpen) {
      setIsOpen(true);
    } else {
      setIsOpen(false);
    }
  }, [locationData, ripspotdata]);

  const handleItemClick = (item, type) => evt => {
    setIsOpen(false);
    handleClick(item, type)(evt);
  };

  const hasData =
    (locationData &&
      locationData.features &&
      locationData.features.length > 0) ||
    (ripspotdata && ripspotdata.length > 0);

  const showResultsCounter = hasData && !isOpen;
  const showCollapseIcon = hasData && isOpen;

  const locationNum =
    locationData && locationData.features && locationData.features.length;
  const ripspotNum = ripspotdata && ripspotdata.length;
  const toalResultsNum = (locationNum || 0) + (ripspotNum || 0);

  //
  // Need to grab only the first 3 members unless we have explicitly opened all results for given list
  //

  // use this for determining if we render "show all" button
  const allRipspotDataToUse = ripspotdata;
  // mask to only 3 ripsopts if now showing all
  const maskedRipspotData = showAllRipspots
    ? ripspotdata
    : ripspotdata && Array.isArray(ripspotdata) && ripspotdata.slice(0, 3);

  // repeat above ^^ for Locations
  const allLocationDataToUse = locationData;
  const maskedLocationData = showAllLocations
    ? locationData
    : locationData.features && {
        ...locationData,
        features: locationData.features.slice(0, 3),
      };

  //
  //
  //
  //
  //
  //
  //
  //

  return (
    <Container isOpen={isOpen}>
      {showResultsCounter && (
        <Wrapper>
          <Title>{toalResultsNum} results found: </Title>
          <StyledButtonIcon
            isOpen
            onClick={toggle}
            width="40px"
            Icon={ViewIcon}
          />
        </Wrapper>
      )}
      <StyledButtonIcon
        isOpen={showCollapseIcon}
        Icon={MinusIconCircleFilled}
        onClick={toggle}
        btnStyle={{ position: 'absolute', top: -20, right: -15 }}
        width="30px"
      />

      <ListWrapper isOpen={isOpen}>
        {!editInProgress && (
          <div>
            <ListTitle>Ripspots</ListTitle>
            <List>
              {(maskedRipspotData &&
                maskedRipspotData.length > 0 &&
                maskedRipspotData.map(ripspot => (
                  <SearchResultItem
                    key={ripspot.id}
                    onClick={handleItemClick(ripspot, 'ripspot')}
                  >
                    <span>{ripspot.name}</span>
                    {ripspot.birthDate && (
                      <span className="year-detail">
                        {ripspot.birthDate.year} -
                      </span>
                    )}
                    {ripspot.deathDate && (
                      <span className="year-detail">
                        {' '}
                        {ripspot.deathDate.year}
                      </span>
                    )}
                  </SearchResultItem>
                ))) || (
                <li style={{ width: '100%', textAlign: 'center' }}>
                  No Ripspots
                </li>
              )}
            </List>
            {allRipspotDataToUse && allRipspotDataToUse.length > 3 && (
              <StyledLink
                href="#!"
                onClick={evt => {
                  evt.preventDefault();
                  setShowAllRipspots(!showAllRipspots);
                }}
              >
                {showAllRipspots ? 'Minimize Ripspots' : 'See all Ripspots'}
              </StyledLink>
            )}
          </div>
        )}
        <div>
          <ListTitle>Locations</ListTitle>
          <List>
            {(maskedLocationData &&
              maskedLocationData.features &&
              maskedLocationData.features.length > 0 &&
              maskedLocationData.features.map(location => (
                <SearchResultItem
                  key={location.id}
                  onClick={handleItemClick(location, 'location')}
                >
                  <span>{location.place_name}</span>
                </SearchResultItem>
              ))) || (
              <li style={{ width: '100%', textAlign: 'center' }}>
                No Locations
              </li>
            )}
          </List>
          {allLocationDataToUse &&
            allLocationDataToUse.features &&
            allLocationDataToUse.features.length > 3 && (
              <StyledLink
                href="#!"
                onClick={evt => {
                  evt.preventDefault();
                  setShowAllLocations(!showAllLocations);
                }}
              >
                {showAllLocations ? 'Minimize Locations' : 'See all Locations'}
              </StyledLink>
            )}
        </div>
      </ListWrapper>
    </Container>
  );
};

Results.propTypes = {
  handleClick: PropTypes.func.isRequired,
  // data: PropTypes.shape({
  //   features: PropTypes.arrayOf(PropTypes.any),
  // }),
  locationData: PropTypes.shape({
    features: PropTypes.arrayOf(PropTypes.any),
  }),
  ripspotdata: PropTypes.any,
  editInProgress: PropTypes.bool,
};

export default Results;

const Container = styled.div`
  position: relative;
  background-color: transparent;
  color: #000;
  width: 95%;
  max-width: 600px;
  min-height: 40px;
  /* margin: 0 auto 25px; */
  margin-left: 15px;
  border-radius: 3px;
  pointer-events: all;
  /* overflow: hidden; */
  transition: background-color 0.3s;

  ${({ isOpen, theme }) =>
    isOpen &&
    css`
      background-color: #fff;
      ${theme.styles.boxShadows['2']};
    `};
`;

const ListWrapper = styled.div`
  padding: 15px;
  ${({ theme, isOpen }) => theme.styles.showhideStyles(isOpen)}

  > div {
    margin-bottom: 15px;
  }
`;

const ListTitle = styled.h3`
  font-size: 1rem;
`;

const List = styled.ul`
  list-style: none;
  border-radius: 5px;
`;

const SearchResultItem = styled.li`
  display: flex;
  align-items: center;
  align-content: center;
  width: 100%;
  height: 2.6rem;
  padding: 10px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: background-color 0.3s;
  &:hover {
    background-color: rgba(120, 120, 120, 0.1);
  }

  span {
    ${({ theme }) => theme.styles.ellipsisMask}
    font-size: 1rem;
    line-height: 1.1rem;
    &.year-detail {
      font-size: 0.8rem;
      color: ${({ theme }) => theme.colors.darkGrey};
      &:nth-child(2) {
        margin-left: 0.5em;
      }
    }
  }
`;

const Wrapper = styled.div`
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  right: 0;
  /* right: -15px; */
  /* top: -20px; */
  font-size: 1rem;
  padding: 3px;
  padding: 5px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  ${({ theme }) => theme.styles.boxShadows['2']}
`;

const Title = styled.h2`
  color: ${({ theme }) => theme.colors.Primary};
  font-size: 1rem;
`;

const StyledButtonIcon = styled(ButtonIcon)`
  ${({ theme, isOpen }) => theme.styles.showhideStyles(isOpen)};
  svg {
    fill: ${({ theme }) => theme.colors.Primary};
  }
`;

const StyledLink = styled(Link)`
  display: inline-block;
  margin-top: 5px;
  margin-left: 2px;
`;
