/**
 *
 * Marker DEMO
 *
 * DefaultMarker / DemoMarker
 *
 *
 */

import React, { memo, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { Layer, Feature, Marker, Popup } from 'react-mapbox-gl';
import styled from 'styled-components';

// import RipspotPin from 'images/ripspot-pin/ripspot-pin-400px.png';
import Location from 'components/icons/Location';

export const DefaultMarker = ({ coords, renderPopup, showPoint, isTouchDevice }) => {
  const [isOpen, setIsOpen] = useState(true);
  const toggle = useCallback(() => setIsOpen(!isOpen));
  return (
    <>
      <Marker
        draggable
        coordinates={coords}
        anchor="bottom"
        style={{ height: '40px', opacity: isTouchDevice ? 0 : 1 }}
        onClick={toggle}
        className="marker"
      >
        {/* <StyledImg src={Location} alt={`ripspot pin at ${coords}`} /> */}
        <Location />
      </Marker>
      {showPoint && (
        <Layer type="symbol" id="marker" layout={{ 'icon-image': 'marker-15' }}>
          <Feature coordinates={coords} />
        </Layer>
      )}
      {isOpen && renderPopup && (
        <Popup
          coordinates={coords}
          offset={{
            'bottom-left': [12, -38],
            bottom: [0, -58],
            'bottom-right': [-12, -38],
          }}
        >
          {renderPopup({ setIsOpen })}
        </Popup>
      )}
    </>
  );
};

DefaultMarker.propTypes = {
  coords: PropTypes.arrayOf(PropTypes.number),
  renderPopup: PropTypes.func,
  showPoint: PropTypes.bool,
};

export default memo(DefaultMarker);

const StyledImg = styled.img`
  height: 100%;
  width: auto;
`;
