/**
 *
 * Marker
 *
 *
 */

import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import { Layer, Feature, Popup } from 'react-mapbox-gl';
// import styled from 'styled-components';
import uuid from 'uuid/v4';

import styled from 'styled-components';

import CustomPopup from './CustomPopup';

export const CustomMarker = ({
  ripspot,
  pinName,
  coords,
  isTouchDevice,
  isHoverDevice,
  handleOnClick,
}) => {
  const [id] = useState(uuid());
  const [isOpen, setIsOpen] = useState(false);
  const [isHoverFromMarker, setIsHoverFromMarker] = useState(false);

  const toggle = (evt) => {
    setIsOpen(!isOpen);
  };



  return (
    <>
      <Layer
        type="symbol"
        id={`position-marker_${id}`}
        // className="position-marker"
        // paint={{
        //   'icon-opacity': disabled ? 0.45 : 1,
        // }}

        layout={{
          'icon-image': pinName,
          'icon-size': 0.75,
          'icon-offset': [0, -45],
          'icon-allow-overlap': true
          // 'icon-offset': [0, -25], // original
        }}
        // layout={{ 'icon-image': 'ripspotpin-xs', 'icon-size': 0.75 }} // marker-15
      >
        <Feature
          coordinates={coords}
          onClick={isTouchDevice ? toggle : handleOnClick}
          onMouseEnter={
            isHoverDevice &&
            (mapObject => {
              if (isTouchDevice) return;
              setIsOpen(true);
            })
          }
          onMouseLeave={
            isHoverDevice &&
            (mapObject => {
              if (isTouchDevice) return;
              setIsOpen(false);
            })
          }
        />
      </Layer>
      {isOpen && (
        <>
          <Popup
            coordinates={coords}
            offset={{
              'bottom-left': [12, -38],
              bottom: [0, -58],
              'bottom-right': [-12, -38],
            }}
            onClick={(evt) => {
              evt.originalEvent.preventDefault();
            }}
          >
            <CustomPopup
              isTouchDevice={isTouchDevice}
              onCloseClick={toggle}
              ripspot={ripspot}
              isOpen={isOpen}
              handleCtaClick={handleOnClick}
            />
          </Popup>
        </>
      )}
    </>
  );
};

CustomMarker.propTypes = {
  coords: PropTypes.arrayOf(PropTypes.number).isRequired,
  pinName: PropTypes.string.isRequired,
  ripspot: PropTypes.any.isRequired,
  isTouchDevice: PropTypes.bool,
  isHoverDevice: PropTypes.bool,
  handleOnClick: PropTypes.func,
};

export default memo(CustomMarker);
