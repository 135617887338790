/* eslint-disable react/jsx-indent */
/**
 *
 * PinPopup
 *
 *
 */

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import CloseButton from 'components/CloseButton';
import Button from 'components/Elements/Button';
import Input from 'components/Elements/Input';

const closeBtnStyles = { height: '20px', width: '25px', fill: '#006838' };

const PinPopup = ({
  id,
  draggable,
  isTouchDevice,
  onRequestClose,
  onEditPinClick,
  onEditRipspotClick,
  onViewRipspotClick,
  onSaveClick,
  onCancelClick,
  ripspot,
  pin,
  pinIndex,
  newMarker,
  editInProgress,
  onDeleteMarker,
  onMouseLeave,
  // NEW
  isFirstPin,
  handleCancelAdditionalPin,
  editModePinId,
  openPopup,
}) => {
  const defaultLabel = (pin && pin.pinLabel) || '';
  const [labelValue, setLabelValue] = useState(defaultLabel);

  const showNormalRipspots = !editInProgress && ripspot;

  const handleNewPinSave = () => {
    onSaveClick(labelValue);
    openPopup(false);
  };

  // const handleOnBlur = () => {
  //   onSaveClick(labelValue);
  // };

  const handleCloseBtnClick = evt => {
    onSaveClick(labelValue);
    onRequestClose(evt);
  };

  // *
  // *
  // *  Normal Ripspots on map pin popup
  // *
  // *
  if (showNormalRipspots) {
    const {
      biography,
      pins,
      photoGallery,
      birthDate,
      deathDate,
      name,
      id,
    } = ripspot;
    const birthYear = new Date(birthDate).getFullYear();
    const deathYear = new Date(deathDate).getFullYear();
    const image = photoGallery.length > 0 && photoGallery[0];

    return (
      <Container>
        <CloseButton
          onClick={handleCloseBtnClick}
          style={closeBtnStyles}
          svgStyle={closeBtnStyles}
        />
        <Wrapper>
          <RipspotName>{name}</RipspotName>
          <Dates>
            {birthYear} - {deathYear}
          </Dates>
          {image && (
            <ImageWrapper>
              <Image src={image.url} alt="" />
            </ImageWrapper>
          )}
          <Controls>
            <StyledButton primary onClick={onEditRipspotClick(id)}>
              Edit
            </StyledButton>
            <StyledButton light onClick={onViewRipspotClick(id)}>
              View Memorial
            </StyledButton>
          </Controls>
        </Wrapper>
      </Container>
    );
  }

  const showTouchDevice = isTouchDevice && draggable;
  const showEditMode = editInProgress && !draggable;

  const showTouchDevicePrompt = isTouchDevice && draggable;
  const showDesktopPrompt = !isTouchDevice && draggable;

  //

  //
  // EDIT Ripspot PinPopup
  //

  // *
  // *
  // *  EDIT Ripspot PinPopup
  // *
  // *

  if (isTouchDevice && editModePinId) {
    return null;
  }

  return (
    <Container onMouseLeave={onMouseLeave}>
      {!newMarker && (
        <CloseButton
          onClick={handleCloseBtnClick}
          style={closeBtnStyles}
          svgStyle={closeBtnStyles}
        />
      )}
      <Wrapper>
        <>
          {showTouchDevicePrompt && <Title>Drag map to place pin</Title>}
          {showDesktopPrompt && <Title>Drag to exact spot</Title>}
          <label htmlFor="pinLabel">
            <StyledInput
              id="pinLabel"
              name="label"
              placeholder="Describe location..."
              type="text"
              value={labelValue}
              onChange={evt => setLabelValue(evt.target.value)}
              // onBlur={handleOnBlur}
              maxLength="30"
            />
          </label>
          {!newMarker && draggable && (
            <Controls>
              <StyledButton
                tertiary
                onClick={handleCancelAdditionalPin}
                style={{ marginTop: '20px' }}
              >
                Cancel
              </StyledButton>
              <StyledButton
                primary
                onClick={handleNewPinSave}
                style={{ marginTop: '20px' }}
              >
                Done
              </StyledButton>
            </Controls>
          )}
          {/* {newMarker && !isTouchDevice && draggable && (
            <Controls>
              {!isFirstPin && (
                // Additional New Pin!
                <StyledButton
                  tertiary
                  onClick={handleCancelAdditionalPin}
                  style={{ marginTop: '20px', width: '100%' }}
                >
                  Cancel newMarker
                </StyledButton>
              )}
              <StyledButton
                primary
                onClick={handleNewPinSave}
                style={{ marginTop: '20px', width: '100%' }}
              >
                Done
              </StyledButton>
            </Controls>
          )} */}
          {/* // TOUCH DEVICES */}
          {newMarker && isTouchDevice && (
            <Controls>
              <StyledButton
                primary
                onClick={onSaveClick}
                style={{ marginTop: '20px' }}
              >
                Done
              </StyledButton>
            </Controls>
          )}
        </>
        {showEditMode ? (
          <>
            <Controls style={{ marginTop: 5, marginBottom: 5 }}>
              <StyledButton dangerous onClick={() => onDeleteMarker(pin.id)}>
                Delete
              </StyledButton>
              <StyledButton primary onClick={onEditPinClick}>
                Edit Location
              </StyledButton>
            </Controls>
          </>
        ) : (
          <span />
        )}
      </Wrapper>
    </Container>
  );
};

PinPopup.propTypes = {
  onRequestClose: PropTypes.func.isRequired,
  draggable: PropTypes.bool.isRequired,
  onSaveClick: PropTypes.func.isRequired,
  onCancelClick: PropTypes.func,
  onEditPinClick: PropTypes.func.isRequired,
  onEditRipspotClick: PropTypes.func,
  onViewRipspotClick: PropTypes.func,
  newMarker: PropTypes.bool,
  ripspot: PropTypes.any,
  pin: PropTypes.any,
  pinIndex: PropTypes.number,
  editInProgress: PropTypes.bool,
  isTouchDevice: PropTypes.bool,
  onDeleteMarker: PropTypes.func,
  // NEW
  isFirstPin: PropTypes.bool,
};

export default PinPopup;

const Container = styled.div`
  color: ${({ theme }) => theme.colors.darkGrey};
  ${({ theme }) => theme.styles.middle}
  position: relative;
  padding: 15px;
  padding-right: 17px;
  padding-top: 22px;
  border: 1px solid rgba(200, 200, 200, 0.5);
  ${({ editModePinId }) =>
    editModePinId &&
    css`
      display: none;
    `}
`;

const BaseTitle = css`
  font-size: 1rem;
  font-family: 'Montserrat', sans-serif;
  text-align: center;
  margin: 0;
  padding: 0;
`;

const Title = styled.h2`
  ${BaseTitle}
  max-width: 250px;
`;

const RipspotName = styled.h2`
  ${BaseTitle}
  font-size: 1.4rem;
`;

const Dates = styled.h3`
  ${BaseTitle}
  margin-top: 20px;
`;

const StyledButton = styled(Button)`
  &:nth-of-type(2) {
    margin-left: 20px;
  }
`;

const StyledInput = styled(Input)`
  margin: 10px 0;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Controls = styled.div`
  display: flex;
  justify-content: space-between;
`;

const ImageWrapper = styled.div`
  height: 100px;
  width: 100px;
  margin: 0 auto;
  /* margin-top: 25px; */
  border-radius: 50%;
  overflow: hidden;
`;

const Image = styled.img`
  width: 100%;
  height: auto;
`;
