/**
 *
 * Minus Icon
 *
 *
 */

import React from 'react';
import PropTypes from 'prop-types';

import { SVGPropTypes } from 'utils/propTypes';

const MinusIcon = ({ width, height, fill, style = {} }) => {
  return (
    <svg
      style={style}
      height={height}
      width={width}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      xmlSpace="preserve"
      version="1.1"
      viewBox="0 0 847 847"
      x="0px"
      y="0px"
      fillRule="evenodd"
      clipRule="evenodd"
    >
      <g>
        <path
          fill={fill}
          className="fil0"
          d="M92 303l662 0c39,0 70,32 70,70l0 100c0,39 -31,71 -70,71l-662 0c-38,0 -70,-32 -70,-71l0 -100c0,-38 32,-70 70,-70zm0 35l662 0c19,0 35,16 35,35l0 100c0,20 -16,35 -35,35l-662 0c-19,0 -35,-15 -35,-35l0 -100c0,-19 16,-35 35,-35z"
        />
      </g>
    </svg>
  );
};

export const MinusIconCircle = ({
  width,
  height,
  fill,
  circleFill,
  barFill,
  style = {},
}) => {
  return (
    <svg
      style={style}
      height={height}
      width={width}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      version="1.1"
      x="0px"
      y="0px"
      viewBox="-255 347 100 100"
      xmlSpace="preserve"
    >
      <path
        fill={circleFill || fill}
        d="M-205,363.2c-18.7,0-33.8,15.2-33.8,33.8s15.1,33.8,33.8,33.8s33.8-15.2,33.8-33.8S-186.3,363.2-205,363.2z M-205,426.8  c-16.4,0-29.8-13.4-29.8-29.8s13.4-29.8,29.8-29.8s29.8,13.4,29.8,29.8S-188.6,426.8-205,426.8z"
      />
      <rect fill={barFill || fill} x="-216.7" y="395" width="23.3" height="4" />
    </svg>
  );
};

export const MinusIconCircleFilled = ({ width, height, fill, style = {} }) => {
  return (
    <svg
      style={style}
      height={height}
      width={width}
      xmlns="http://www.w3.org/2000/svg"
      data-name="Layer 1"
      viewBox="0 0 100 100"
      x="0px"
      y="0px"
    >
      <title>all</title>
      <g>
        <path
          fill={fill}
          d="M50,0a50,50,0,1,0,50,50A50,50,0,0,0,50,0ZM76.68,58H23.32V42H76.68V58Z"
        />
      </g>
    </svg>
  );
};

MinusIcon.propTypes = SVGPropTypes;
MinusIconCircleFilled.propTypes = SVGPropTypes;
MinusIconCircle.propTypes = {
  ...SVGPropTypes,
  circleFill: PropTypes.string,
  barFill: PropTypes.string,
};

export default MinusIcon;
