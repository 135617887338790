/**
 *
 * MapStyleToggle
 *
 *  */

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Button from 'components/Elements/Button';

const MapStyleToggle = ({ isSatellite, handleToggleMapStyle }) => (
  <ContainerBtnToggle type="button" onClick={handleToggleMapStyle}>
    <span>View {isSatellite ? 'Street' : 'Satellite'}</span>
  </ContainerBtnToggle>
);

MapStyleToggle.propTypes = {
  isSatellite: PropTypes.bool.isRequired,
  handleToggleMapStyle: PropTypes.func.isRequired,
};

export default MapStyleToggle;

// was Primary
const ContainerBtnToggle = styled(Button)`
  color: ${({ theme }) => theme.colors.lightGreen};
  background-color: white;
  border: 3px solid ${({ theme }) => theme.colors.lightGreen};
  font-weight: 500;
  padding: 7px;
  font-size: 0.8rem;
`;
