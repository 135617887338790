/**
 *
 * OnBoarding Modal
 *
 * */

import React, { memo, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import Button from 'components/Elements/Button';
import Modal from 'components/modals/index';

const OnBoardingModal = ({ isOpen, onRequestClose }) => {
  // const { location } = useRouter();
  const [showSecondStep, setShowSecondStep] = useState(false);

  useEffect(() => {
    // set(v => !v);
    if (!isOpen) {
      setShowSecondStep(true);
    }
  }, [isOpen]);

  return (
    <StyledModal
      cover
      closeOnOutsideClick
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      // renderTitle={() =>
      //   !isOpen ? (
      //     <span />
      //   ) : (
      //     <ModalTitle isOnSecondStep={showSecondStep}>
      //       {showSecondStep
      //         ? 'First, find a location'
      //         : 'Getting started is easy.'}
      //     </ModalTitle>
      //   )
      // }
      // title=''
      noHeader={true}
      width="calc(100% - 30px)"
      // height={`${heightToUse}px`}
      minHeight="400px"
    >
      <Container>
        {(isOpen &&
          (showSecondStep ? (
            <Content isOnSecondStep={showSecondStep}>
              <h2>First, find a location.</h2>
              <Prompt>
                Zoom into and drag the map to find the location of a meaningful
                place. You can switch to satellite view to help find that
                special spot. Once located, click where you’d like to drop the
                Ripspot pin. You’ll then be able to create the biography and
                build a photo gallery.
              </Prompt>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  marginTop: '15px',
                }}
              >
                <Button
                  primary
                  onClick={evt => {
                    onRequestClose();
                  }}
                >
                  Begin
                </Button>
              </div>
            </Content>
          ) : (
            <Content>
              <h2>Getting started is easy.</h2>
              <Prompt>
                We will guide you through a few simple steps to celebrate a
                loved one, beginning with choosing the location(s) of their
                Ripspot.
              </Prompt>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  marginTop: '15px',
                }}
              >
                <Button
                  primary
                  onClick={evt => {
                    setShowSecondStep(true);
                  }}
                >
                  Continue
                </Button>
              </div>
            </Content>
          ))) || <span />}
      </Container>
    </StyledModal>
  );
};

OnBoardingModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func.isRequired,
};

const mapStateToProps = ({ display, modals }) => ({
  display,
  isOpen: modals.onBoardingModal.isOpen,
});

const withConnect = connect(mapStateToProps);
export default compose(memo, withConnect)(OnBoardingModal);

const StyledModal = styled(Modal)`
  ${({ theme }) =>
    theme.media.max.mobile`
      .animated-div {
        height: 70vh;
      }
  `}
`;

const Container = styled.div`
  height: 100%;
  padding: 15px;
  padding-left: 25px;
  padding-bottom: 20px;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 5px;
  margin-top: 3em;
  h2 {
    font-size: 2.4em;
  }
  ${({ isOnSecondStep }) =>
    isOnSecondStep &&
    css`
      padding-bottom: 2rem;
    `}
`;

const Prompt = styled.p`
  margin: 0;
  padding: 5px;
  font-size: 1.1rem;
  margin-top: 1em;
`;

const ModalTitle = styled.h2`
  font-size: 1.8rem;
  margin-left: 15px;
  ${({ isOnSecondStep }) =>
    !isOnSecondStep &&
    css`
      margin-top: 2rem;
    `}
`;

// const transitions = useTransition(showSecondStep && isOpen, key => key, {
//   from: {
//     position: 'absolute',
//     opacity: 0,
//     transform: 'translate3d(20px, 0, 0)',
//   },
//   enter: { opacity: 1, transform: 'translate3d(-20px, 0, 0)' },
//   leave: item => ({
//     opacity: 0,
//     transform: `translate3d(${-20}px, 0, 0)`,
//   }),
// });
