/* eslint-disable react/prop-types */
/**
 *
 * CustomPopup
 *
 *
 */

import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import CloseButton from 'components/CloseButton';
import Button from 'components/Elements/Button';

const CustomPopup = ({
  ripspot,
  pin,
  onCloseClick,
  isOpen,
  onMouseEnter,
  onMouseLeave,
  handleCtaClick,
  isViewingSingleRipspot,
  isTouchDevice
}) => {
  const handleViewRipspot = (evt) => {
    evt.stopPropagation();
    evt.preventDefault();
    handleCtaClick();
    if (!isOpen) return;
  };

  const handleCloseClick = evt => {
    evt.stopPropagation();
    onCloseClick();
  };

  const birthDate =
    (ripspot && ripspot.birthDate && ripspot.birthDate.year) || 'N/A';
  const deathDate =
    (ripspot && ripspot.deathDate && ripspot.deathDate.year) || 'N/A';
  const name = (ripspot && ripspot.name) || 'N/A';

  // Make sure we dont cover any pins with blank ripspot pin
  if (!ripspot) return null;

  return (
    <Container onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
      {isViewingSingleRipspot ? (
        <>
          <p>{pin.pinLabel}</p>
          {!isTouchDevice && (
            <Instruction>Click pin to view details</Instruction>
          )}
        </>
      ) : (
        <div>
          {isTouchDevice && (
            <CloseWrapper>
              <CloseButton onClick={handleCloseClick} size="24" />
            </CloseWrapper>
          )}
          <DetailsWrapper>
            <p>{name}</p>
          </DetailsWrapper>
          <DateWrapper>
            <p>{birthDate}</p>-<p>{deathDate}</p>
          </DateWrapper>
          {(isTouchDevice ? (
            <Button
              onClick={handleViewRipspot}
              primary
            >
              View Ripspot
            </Button>
          ) : (
            <Instruction>Click pin to view Ripspot</Instruction>
          ))}
        </div>
      )}
    </Container>
  );
};

CustomPopup.propTypes = {
  ripspot: PropTypes.any,
  onCloseClick: PropTypes.func,
  isOpen: PropTypes.bool,
  onMouseOver: PropTypes.func,
  onMouseOut: PropTypes.func,
};

export default CustomPopup;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 140px;
  min-height: 60px;
  padding: 1.5em 2em 1em;

  /* border: 4px solid ${({ theme }) => theme.colors.Primary}; */

  > button {
    font-size: 1.2em;
    max-width: 140px;
  }
  > div {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
`;

const DetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  p {
    /* margin-bottom: 1em; */
  }
  img {
    width: 100%;
    height: auto;
    max-width: 40px;
    border-radius: 50%;
  }
`;

const CloseWrapper = styled.div`
  button {
    right: 0px;
  }
`;

const DateWrapper = styled.div`
  margin: 1em 0;
  display: flex;
  justify-content: center;
  p {
    font-size: 1.4em;
    margin: 0 0.5em;
  }
`;

const Instruction = styled.span`
  color: ${({ theme }) => theme.colors.darkGrey};
  font-size: 0.8rem;
  font-style: italic;
  text-align: center;
  width: 100%;
`;
