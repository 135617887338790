import { Router } from '@reach/router';
import React, { memo, useEffect, useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import SEO from 'components/seo';
import ExploreMap from 'containers/ExploreMap';
import RipspotModal from 'components/modals/RipspotModal/Loadable';
import {
  MAP_SEARCH_ROUTE,
  MAP_CR_LOCATIONS_ROUTE,
  MAP_CR_DETAILS_ROUTE,
  MAP_CR_BIO_ROUTE,
  MAP_CR_PHOTOS_ROUTE,
  MAP_CR_REVIEW_ROUTE,
  MAP_CR_PAYMENT_ROUTE,
  MAP_CR_CONFIRMATION_ROUTE,
} from 'utils/routes';

const ExplorePage = ({ location }) => {
  const [appReady, setAppReady] = useState(false);

  useEffect(() => {
    document.body.classList.remove('fix-nav');
    setAppReady(true);
  }, []);

  return (
    <Container>
      <SEO title={'Map'} />
      <RipspotModal key="ripspot-modal" />
      {appReady && <ExploreMap location={location} />}
      <Router>
        <EmptyComponent path={MAP_SEARCH_ROUTE} />
        <EmptyComponent path={MAP_CR_LOCATIONS_ROUTE} />
        <EmptyComponent path={MAP_CR_DETAILS_ROUTE} />
        <EmptyComponent path={MAP_CR_BIO_ROUTE} />
        <EmptyComponent path={MAP_CR_PHOTOS_ROUTE} />
        <EmptyComponent path={MAP_CR_REVIEW_ROUTE} />
        <EmptyComponent path={MAP_CR_PAYMENT_ROUTE} />
        <EmptyComponent path={MAP_CR_CONFIRMATION_ROUTE} />
      </Router>
    </Container>
  );
};

ExplorePage.propTypes = {
  location: PropTypes.any,
};

export default memo(ExplorePage);

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

/**
 * Just for holding nested routes.
 */
const EmptyComponent = styled.div`
  z-index: -1;
  position: fixed;
`;
