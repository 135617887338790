/**
 *
 * ViewIcon
 *
 *
 */

import React from 'react';
import { SVGPropTypes } from 'utils/propTypes';

const ViewIcon = ({ fill, width = '100%', height = '100%', style = {} }) => (
  <svg
    height={height}
    width={width}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    version="1.1"
    x="0px"
    y="0px"
    viewBox="0 0 100 100"
    enableBackground="new 0 0 100 100"
    xmlSpace="preserve"
  >
    <path
      fill={fill}
      d="M79.412,49.428C76.07,39.769,63.426,32.485,50,32.485s-26.07,7.284-29.412,16.943L20.39,50l0.198,0.572  C23.93,60.231,36.574,67.516,50,67.516s26.07-7.284,29.412-16.943L79.609,50L79.412,49.428z M50,63.902  c-7.667,0-13.903-6.236-13.903-13.902c0-7.667,6.237-13.903,13.903-13.903c7.666,0,13.902,6.237,13.902,13.903  C63.902,57.666,57.666,63.902,50,63.902z M24.109,50c1.93-4.869,6.728-8.981,12.819-11.469C34.235,41.596,32.597,45.61,32.597,50  c0,4.391,1.638,8.404,4.331,11.469C30.836,58.982,26.039,54.869,24.109,50z M63.073,61.469c2.693-3.064,4.331-7.077,4.331-11.469  c0-4.391-1.638-8.404-4.331-11.469c6.091,2.488,10.89,6.601,12.819,11.469C73.961,54.869,69.164,58.982,63.073,61.469z"
    />
  </svg>
);

ViewIcon.propTypes = SVGPropTypes;

export default ViewIcon;
