/**
 *
 * Ripspot Marker
 *
 *
 */

import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { debounce } from 'lodash';
import { Layer, Feature, Popup } from 'react-mapbox-gl';
import { createGlobalStyle, css } from 'styled-components';

import { setEditInProgress } from 'actions/ripspots';

import { sendCustomGaEvent } from 'utils/ga';
import useOnChangeWatcher from 'utils/hooks/useOnChangeWatcher';

import CustomPopup from './CustomPopup';
import PinPopup from './PinPopup';

// const point = [-75.130841, 40.309922];
// const point2 = [-75.130841, 43.309922];

// const POSITION_CIRCLE_PAINT = {
//   'circle-stroke-width': 4,
//   'circle-radius': 10,
//   'circle-blur': 0.15,
//   'circle-color': '#3770C6',
//   'circle-stroke-color': 'white',
// };
// type="circle"
// paint={POSITION_CIRCLE_PAINT} // -> on layer comp

const GlobalStyle = createGlobalStyle`
  .mapboxgl-popup-content {
    ${({ theme }) => `${theme.styles.boxShadows['3']} !important`};
    border-radius: 10px !important;
    ${({ editInProgress }) =>
      editInProgress &&
      css`
        padding: 0 !important;
        margin: 0 !important;
      `}

  }

  .mapboxgl-canvas-container { 
    /* // super important!!! show crosshair so hand "grab" doesnt block view */
    /* cursor: crosshair !important; */
    ${({ useCrosshair }) =>
      useCrosshair &&
      css`
        cursor: crosshair !important;
      `}
  }

  .position-marker {
    width: 100px;
    height: 100px;
    background-color: pink !important;
  }
`;

const RipspotMarker = ({
  id,
  coords,
  renderPopup,
  updatePin,
  ripspot,
  currentPinName,
  currentOpenPopupId,
  setCurrentOpenPopupId,
  pin,
  pinIndex,
  newMarker = false,
  showPopup = false,
  showPoint,
  disabled,
  draggable,
  onEditPinClick,
  editInProgress,
  isInExistingPinEditMode,
  isViewingSingleRipspot,
  onSaveClick,
  onCancelClick,
  onEditRipspotClick,
  onViewRipspotClick,
  onDeleteMarker,
  dispatch,
  // * NEW Props
  setZoom,
  isTouchDevice,
  isHoverDevice,
  isFirstPin,
  handleCancelAdditionalPin,
  handleOnClick,
  // onPinDragEnd,
}) => {
  const [useCrosshair, setUseCrosshair] = useState(false);
  const [isOpen, setIsOpen] = useState(
    editInProgress && (currentOpenPopupId === id || showPopup),
  );

  const ripspots = useSelector(state => state.ripspots);

  const pinClickToggle = evt => {
    //
    //
    //
    //
    //
    //
    //
    //
    //
    //
    //
    //
    // if (evt && evt.originalEvent && evt.originalEvent) {
    //   //
    //   // TODO: cant get event bubbling to stop clicks from hitting the map after a click on our pin
    //   //

    //   // will this work???
    //   evt.originalEvent.bubbleEvent = false; // trying to block map click captures on layers

    // }
    // if (evt && evt.originalEvent && evt.originalEvent.defaultPrevented) {
    //   evt.originalEvent.preventDefault();

    // }
    // if (evt && evt.originalEvent && evt.originalEvent.stopPropagation) {

    //   evt.originalEvent.stopPropagation();
    // }
    if (evt.originalEvent) {
      evt.originalEvent.preventDefault();
      evt.originalEvent.stopPropagation();
    }

    //
    //
    //
    setCurrentOpenPopupId(isOpen ? '' : id);
    setIsOpen(!isOpen);

    if (!editInProgress && isViewingSingleRipspot && !isTouchDevice) {
      //
      // Pin Click handler for opening Ripspot Memorial Modal
      //
      handleOnClick && handleOnClick(evt);
      sendCustomGaEvent(
        'Search',
        'View Ripspot Modal',
        `Search - View Ripspot Modal ${ripspot.id}`,
      );
    }
  };

  useEffect(() => {
    // tracking locally allows us to force the pin open initially,
    // but also allows it to then close
    if (currentOpenPopupId === id) {
      setIsOpen(true);
    } else if (!showPopup) {
      setIsOpen(false);
    }
  }, [currentOpenPopupId]);

  useOnChangeWatcher(editInProgress, () => {
    if (editInProgress) {
      //
      // NOT sure what this is doing, keep an eye out
      //
      dispatch(setEditInProgress(false));
    }
  });

  const onDragEnd = ({ lngLat }) => {
    const newCoords = [lngLat.lng, lngLat.lat];
    if (updatePin) {
      updatePin(id, newCoords, pin.pinLabel, pin.pinDescription);
    }
    setUseCrosshair(false);
    // add any new apis I want to pass down
    //
    //
    // onPinDragEnd && onPinDragEnd({ lngLat, newCoords, id });
  };

  const onDragStart = e => {
    setUseCrosshair();
  };

  const onDrag = () => {};

  const showEditPin = isOpen && editInProgress;
  const showCustomPin = isOpen && !editInProgress;

  const handleOnSaveClick = debounce((pinLabel, pinDescription) => {
    onSaveClick(pin.id, pin.pinCoords, pinLabel, pinDescription);
  }, 300);

  const handleCancelAddPin = () => {
    handleCancelAdditionalPin(id);
  };

  return (
    <>
      <GlobalStyle
        useCrosshair={useCrosshair}
        editInProgress={editInProgress}
      />
      <Layer
        type="symbol"
        id={`position-marker_${id}`}
        className="position-marker"
        paint={{
          'icon-opacity': disabled ? 0.45 : 1,
        }}
        layout={{
          'icon-image': currentPinName, //  'ripspotpin-xs',
          'icon-size': 0.75,
          'icon-offset': [0, editInProgress ? -25 : -45],
          'icon-allow-overlap': true,
          // 'icon-offset': [0, -25], // original / edit ripspot
        }}
        style={{ cursor: 'pointer !important;' }}
      >
        <Feature
          draggable={draggable}
          coordinates={coords}
          onDragEnd={onDragEnd}
          onDragStart={onDragStart}
          onDrag={onDrag}
          onMouseEnter={mapObject => {
            //
            setIsOpen(true);
          }}
          onMouseLeave={mapObject => {
            // editInProgress &&
            if (editInProgress) {
              return;
            }
            // !editInProgress &&
            setIsOpen(false);
          }}
          onClick={(...props) => {
            pinClickToggle(...props);
          }}
        />
      </Layer>
      {showPoint && (
        <Layer
          type="symbol"
          id={`marker-point_${id}`}
          layout={{
            'icon-image': 'marker-15',
            // 'icon-allow-overlap': true
          }}
        >
          <Feature
            draggable={draggable}
            coordinates={coords}
            onDragEnd={onDragEnd}
            onDragStart={onDragStart}
            onDrag={onDrag}
            onClick={pinClickToggle}
            onMouseEnter={mapObject => {}}
            onMouseLeave={mapObject => {}}
          />
        </Layer>
      )}

      {showCustomPin && (
        <Popup
          coordinates={coords}
          offset={{
            'bottom-left': [12, -38],
            bottom: [0, -58],
            'bottom-right': [-12, -38],
          }}
          onClick={() => {}}
        >
          <CustomPopup
            onCloseClick={() => setIsOpen(v => !v)}
            ripspot={ripspot}
            pin={pin}
            isViewingSingleRipspot={isViewingSingleRipspot}
            isOpen={isOpen}
            onMouseOver={
              isHoverDevice &&
              (() => {
                setIsOpen(true);
              })
            }
            onMouseOut={
              isHoverDevice &&
              (() => {
                setIsOpen(false);
              })
            }
          />
        </Popup>
      )}

      {showEditPin && (
        <Popup
          className="custom-popup"
          coordinates={coords}
          anchor="bottom"
          offset={{
            'bottom-left': [12, -50],
            bottom: [0, -58],
            'bottom-right': [-12, -50],
          }}
        >
          {renderPopup ? (
            renderPopup({ currentOpenPopupId, setCurrentOpenPopupId })
          ) : (
            <PinPopup
              id={id}
              onRequestClose={pinClickToggle}
              draggable={draggable}
              newMarker={newMarker}
              isTouchDevice={isTouchDevice}
              ripspot={ripspot}
              pin={pin}
              pinIndex={pinIndex}
              onMouseLeave={() => {
                // setIsOpen(false);
              }}
              // state.location
              onEditPinClick={onEditPinClick}
              editInProgress={editInProgress}
              onSaveClick={handleOnSaveClick}
              onCancelClick={onCancelClick}
              onEditRipspotClick={onEditRipspotClick}
              onViewRipspotClick={onViewRipspotClick}
              onDeleteMarker={onDeleteMarker}
              // NEW
              isFirstPin={isFirstPin}
              handleCancelAdditionalPin={handleCancelAddPin}
              openPopup={setIsOpen}
              dispatch
              reRender
            />
          )}
        </Popup>
      )}
    </>
  );
};

RipspotMarker.propTypes = {
  coords: PropTypes.arrayOf(PropTypes.number),
  id: PropTypes.string,
  currentPinName: PropTypes.string,
  updatePin: PropTypes.func,
  renderPopup: PropTypes.func,
  onEditPinClick: PropTypes.func.isRequired,
  onSaveClick: PropTypes.func.isRequired,
  onCancelClick: PropTypes.func,
  onEditRipspotClick: PropTypes.func,
  onViewRipspotClick: PropTypes.func,
  ripspot: PropTypes.any,
  pin: PropTypes.any,
  pinIndex: PropTypes.number,
  newMarker: PropTypes.bool,
  showPopup: PropTypes.bool,
  disabled: PropTypes.bool,
  editInProgress: PropTypes.bool,
  showPoint: PropTypes.bool,
  draggable: PropTypes.bool,
  // current pin popup open
  currentOpenPopupId: PropTypes.string.isRequired,
  setCurrentOpenPopupId: PropTypes.func.isRequired,
  onDeleteMarker: PropTypes.func, // unless we add to a "new marker" in futurte, not required, RipspotMarker means it has been added to redux and the ripspot data already
  // NEW
  isFirstPin: PropTypes.bool,
  handleCancelAdditionalPin: PropTypes.func,
};

export default RipspotMarker;

// !editInProgress ? (
//   <CustomPopup
//     onCloseClick={() => setIsOpen(v => !v)}
//     ripspot={ripspot}
//     isOpen={isOpen}
//     onMouseOver={
//       isHoverDevice &&
//       (() => {
//
//
//         setIsOpen(true);
//       })
//     }
//     onMouseOut={
//       isHoverDevice &&
//       (() => {
//
//
//         setIsOpen(false);
//       })
//     }
//   />
// ) :
