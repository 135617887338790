/**
 *
 * Select Pin Modal
 *
 *
 */

import React, { memo, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';

// import Theme from 'utils/theme';

// import Close from 'components/icons/Close';
import Button from 'components/Elements/Button';
// import Checkbox from 'components/Elements/Checkbox';
import CheckboxV2 from 'components/Elements/Checkbox/v2';
import Modal from 'components/modals/index';

import PinsList from './PinsList';

// const { colors } = Theme;

const SelectPinModal = ({
  isOpen,
  onRequestClose,
  onConfirm,
  currentPinName,
}) => {
  const handleConfirmClick = currentPin => {
    //
    onConfirm(currentPin);
  };

  // useEffect(() => {
  //
  //   //
  //   setCurrentPin(currentPinName);
  // }, [currentPinName]);

  return (
    <Modal
      noOverlay
      closeOnOutsideClick
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      alignItems="flex-start"
      spacing={40}
      closeIconSize="md"
      title="Select a pin style."
    >
      <Container>
        {/* <Title>Please select a pin style.</Title> */}
        <Content>
          <p>
            You’ll be able to mark more than one location, but first, select a pin style. If you have a suggestion for a pin style not shown here, we’d love to help. Feel free to 
            <a href='mailto:info@ripspot.com?subject="New Pin Inquiry"'>
              contact us
            </a>
            .
          </p>
          <PinsList
            currentPinName={currentPinName}
            onPinSelect={handleConfirmClick}
            // setIsArmedServices={setIsArmedServices}
            // branch={branch}
            // BranchSelector={BranchSelector()}
          />
          {/* <CheckboxWrapper>
            <CheckboxV2
              checkFill="#33333399"
              initialValue={isArmedServices}
              value={isArmedServices}
              onChange={checked => {
                setIsArmedServices(checked);
                if (!checked) {
                  setBranch('');
                }
              }}
              renderLabel={() => <LabelText>U.S. Veteran</LabelText>}
            />
          </CheckboxWrapper> */}
          <Controls>
            {/* <StyledButton
              light
              disabled={!currentPin}
              onClick={handleConfirmClick}
            >
              Confirm
            </StyledButton> */}
          </Controls>
        </Content>
      </Container>
    </Modal>
  );
};

const BranchSelector = ({ isArmedServices, setBranch, branch }) => {
  return (
    <AnimatedWrapper isOpen={isArmedServices}>
      <Label>Branch:</Label>
      <ReactSelect
        portal
        placeholder="Select"
        value={branch}
        menuPlacement="auto"
        options={options}
        handleChange={opt => setBranch(opt)}
      />
    </AnimatedWrapper>
  );
};

SelectPinModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  currentPinName: PropTypes.string,
};

const mapStateToProps = ({ display }) => ({ display });
const withConnect = connect(mapStateToProps);

export default compose(memo, withConnect)(SelectPinModal);

const Container = styled.div`
  height: 100%;
  padding: 15px;
  overflow: hidden;
  @media screen and (min-width: 600px) {
    width: 600px;
  }
`;

const Content = styled.div`
  padding-top: 25px;
`;

const Title = styled.h2`
  font-size: 1.5rem;
`;

const CheckboxWrapper = styled.div`
  padding: 15px 0px;
`;

const Controls = styled.div`
  color: ${({ theme }) => theme.colors.darkGrey};
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  height: 85px;
  padding-right: 2rem;
  margin-bottom: 2rem;
`;

const StyledButton = styled(Button)`
  /* background-color: ${({ theme }) => theme.colors.lightGreen}; */
  color: #fff;
  font-size: 1rem;
  padding: 0.5rem 1rem;
  margin-left: 10px;
`;

const LabelText = styled.span`
  color: ${({ theme }) => theme.colors.darkGrey};
`;
