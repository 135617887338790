/**
 *
 * PinsList Icons List
 *
 */

import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import ReactSelect from 'components/lib/ReactSelect';

import {
  miscIcons,
  petIcons,
  servicesIcons,
  religionIcons,
} from 'components/lib/Mapbox/utils/icons';

import PinsListItem from './PinsListItem';

const options = [
  { value: 'army', label: 'Army' },
  { value: 'navy', label: 'Navy' },
  { value: 'airForce', label: 'Air Force' },
  { value: 'spaceForce', label: 'Space Force' },
  { value: 'marines', label: 'Marines' },
  { value: 'coastGuard', label: 'Coast Guard' },
];

const PinsList = ({ currentPinName, onPinSelect }) => {
  const [currentPin, setCurrentPin] = useState(currentPinName);
  const [isArmedServices, setIsArmedServices] = useState(false);
  const [branch, setBranch] = useState('');

  return (
    <Wrapper>
      <h4>Pet Pins:</h4>
      <List>
        {petIcons.map(({ name, image, displayName }) => (
          <PinsListItem
            currentPin={currentPin}
            name={name}
            displayName={displayName}
            image={image}
            setIsArmedServices={setIsArmedServices}
            setCurrentPin={setCurrentPin}
            onPinSelect={onPinSelect}
            key={name}
          />
        ))}
      </List>
      <h4>People Pins:</h4>
      <List>
        {miscIcons.map(({ name, image, displayName }) => (
          <PinsListItem
            currentPin={currentPin}
            name={name}
            displayName={displayName}
            image={image}
            setIsArmedServices={setIsArmedServices}
            setCurrentPin={setCurrentPin}
            onPinSelect={onPinSelect}
            key={name}
          />
        ))}
        {religionIcons.map(({ name, image, displayName }) => (
          <PinsListItem
            currentPin={currentPin}
            name={name}
            displayName={displayName}
            image={image}
            setIsArmedServices={setIsArmedServices}
            setCurrentPin={setCurrentPin}
            onPinSelect={onPinSelect}
            key={name}
          />
        ))}
      </List>
      <h4>Veteran Pins:</h4>
      <List style={{ paddingBottom: isArmedServices ? '5px' : '' }}>
        {servicesIcons.map(({ name, image, displayName }) => (
          <PinsListItem
            currentPin={currentPin}
            name={name}
            displayName={displayName}
            image={image}
            setIsArmedServices={setIsArmedServices}
            setCurrentPin={setCurrentPin}
            onPinSelect={onPinSelect}
            key={name}
          />
        ))}
      </List>
      <BranchContainer isOpen={isArmedServices}>
        <Label>Branch:</Label>
        <ReactSelect
          portal
          placeholder="Select"
          value={branch}
          menuPlacement="auto"
          options={options}
          handleChange={opt => {
            setBranch(opt);
            onPinSelect('USVeteran');
          }}
        />
      </BranchContainer>
    </Wrapper>
  );
};

PinsList.propTypes = {
  setIsArmedServices: PropTypes.func,
  branch: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

export default memo(PinsList);

const List = styled.ul`
  display: flex;
  flex-wrap: wrap;
  padding-top: 25px;
  padding-bottom: 25px;
`;

const BranchContainer = styled.div`
  ${({ isOpen, theme }) => theme.styles.showhideStyles(isOpen, 0.5)}
  width: 100%;
  max-width: 160px;
  margin-left: 0.5rem;
  margin-bottom: 1rem;
`;

const Wrapper = styled.div`
  padding-top: 2rem;
  h4 {
    font-size: 1.4rem;
    margin-left: 0.5rem;
  }
`;

const Label = styled.label`
  color: ${({ theme }) => theme.colors.darkGrey};
  display: inline-block;
  font-family: 'Montserrat', sans-serif;
  font-size: 1rem;
  padding: 5px 0px;
`;
