/**
 *
 * Ripspot Modal
 *
 *
 */

import React, { memo, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Spinner from 'components/Spinner';

import { closeRipspotMemorialModal } from 'actions/modals';
import {
  hydrateRipspot,
  setViewSingleRipspot,
  setBoundPins,
  removeCurrentRipspot,
} from 'actions/ripspots';

import Modal from 'components/modals/index';
import RipspotMemorial from 'components/RipspotMemorial';
// import AnimatedTitle from 'components/AnimatedTitle';

import { getPublicRipspotById } from 'utils/api/ripspots';

/**
 * RipspotMemorialModal
 *
 * @param {*} { isOpen, dispatch, metadata, ripspotId }
 * @returns
 */
const RipspotMemorialModal = ({ isOpen, dispatch, metadata, ripspotId }) => {
  const [ripspot, setRipspot] = useState({});
  const [hasMultiplePins, setHasMultiplePins] = useState(false);

  /**
   * Click to view a specific ripspot handler
   *
   *
   * @param {*} ripspot - ripspot user wants to VIEW
   */
  const onViewRipspotClick = () => {
    // TODO Refactor to prevent redundant action calls - If
    // 1. hydrate ripspot (puts data in state for pins and edit info)
    dispatch(hydrateRipspot(ripspot));
    dispatch(setViewSingleRipspot(true));
    dispatch(closeRipspotMemorialModal());

    // set bounding view box around all pins
    dispatch(setBoundPins(ripspot.pins));
  };

  useEffect(() => {
    if (isOpen && ripspotId) {
      // OPEN!
      //
      // fetch data for ripspotId
      //
      (async () => {
        //
        const ripspotRes = await getPublicRipspotById(ripspotId);

        setRipspot(ripspotRes);
        const multiplePins = ripspotRes.pins.length > 1;
        setHasMultiplePins(multiplePins);
      })();
    } else {
      // not open
    }
  }, [isOpen, ripspotId]);

  const hasData = Object.keys(ripspot).length > 0;

  const onRequestClose = () => {
    dispatch(closeRipspotMemorialModal());
    dispatch(setViewSingleRipspot(false));
    dispatch(removeCurrentRipspot());
    setRipspot({});
    setHasMultiplePins(false);
  };

  return (
    <Modal
      noMinMax
      closeOnOutsideClick
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      width="calc(100vw - 60px)"
      height="calc(100vh - 80px)"
      isRipspotModal={true}
    >
      {hasData ? (
        <>
          <Container isOpen={hasData}>
            <RipspotMemorial
              isModal
              ripspot={ripspot}
              onViewRipspotClick={onViewRipspotClick}
              showComments
            />
          </Container>
        </>
      ) : (
        <Spinner loading={hasData} cover={true} size={50} />
      )}
    </Modal>
  );
};

RipspotMemorialModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
  metadata: PropTypes.object,
  ripspotId: PropTypes.string,
};

const mapStateToProps = ({ modals, location }) => ({
  location,
  isOpen: modals.ripspotMemorialModal.isOpen,
  metadata: modals.ripspotMemorialModal.metadata,
  ripspotId: modals.ripspotMemorialModal.ripspotId,
});

const withConnect = connect(mapStateToProps);
export default compose(memo, withConnect)(RipspotMemorialModal);

const Container = styled.div`
  height: 100%;
`;
