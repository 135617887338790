import React, { memo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import uuid from 'uuid';

import { CheckmarkAlt } from 'components/icons/Checkmark';

const CheckboxV2 = ({
  initialValue,
  onChange,
  style,
  visible = true,
  fill = '#000',
  checkFill = '#333',
  label,
  renderLabel,
}) => {
  const [key] = useState(uuid());
  const [checked, setChecked] = useState(initialValue || false);
  // eslint-disable-next-line
  const [focused, setFocused] = useState(false);

  useEffect(() => {
    setChecked(initialValue);
  }, [initialValue]);

  const handleChange = e => {
    const isChecked = e.target.checked;
    setChecked(isChecked);
    onChange && onChange(isChecked);
  };

  let LabelCompoennt = null;
  if (renderLabel) {
    LabelCompoennt = renderLabel;
  } else if (label) {
    LabelCompoennt = () => <span>{label}</span>;
  }

  return (
    <Container visible={visible} style={style}>
      <Input
        id={key}
        type="checkbox"
        checked={checked}
        onChange={handleChange}
        onFocus={() => setFocused(true)}
        onBlur={() => setFocused(false)}
      />
      <Label htmlFor={key}>
        <Box>
          <SVGWrapper checked={checked}>
            <CheckmarkAlt fill={checkFill} />
          </SVGWrapper>
        </Box>
        {LabelCompoennt && <LabelCompoennt />}
      </Label>
    </Container>
  );
};

CheckboxV2.propTypes = {
  initialValue: PropTypes.bool,
  onChange: PropTypes.func,
  style: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

export default CheckboxV2;

const Container = styled.div`
  position: relative;

  ${({ visible }) =>
    !visible &&
    css`
      opacity: 0;
      pointer-events: none;
    `}
`;

const Input = styled.input`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 20px;
  margin: 0;
  opacity: 0;
  cursor: pointer;
`;

const Label = styled.label`
  color: #000;
  display: flex;
  align-items: center;
  font-size: 0.8rem;
  cursor: pointer;
`;

const Box = styled.div`
  ${({ theme }) => theme.styles.middle}
  position: relative;
  width: 20px;
  height: 20px;
  margin-right: 15px;
  border: 1px solid #333;
`;

const SVGWrapper = styled.div`
  ${({ theme, checked }) => theme.styles.showhideStyles(checked)};
  position: absolute;
  width: 30px;
  height: 30px;
  transform: translate3d(8px, -8px, 0);
  svg {
    position: relative;
    z-index: 1;
  }
`;
