/**
 *
 * CloseButton
 *
 *
 */

import React, { memo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import ButtonIcon from 'components/Elements/ButtonIcon';
import CloseIcon from 'components/icons/Close';

export const CloseButtonBase = ({ onClick, width = '30px' }) => (
  <ButtonIcon onClick={onClick} Icon={CloseIcon} width={width} />
);

CloseButtonBase.propTypes = {
  width: PropTypes.string,
  onClick: PropTypes.func,
};

const CloseButton = ({
  onClick,
  size,
  style = {},
  svgStyle = {},
  className,
  renderIcon = null,
}) => {
  if (size) {
    style.width = size;
    style.height = size;
    svgStyle.height = size;
    svgStyle.width = size;
  }
  return (
    <CloseIconWrapper onClick={onClick} style={style} className={className}>
      {renderIcon ? renderIcon() : <CloseIcon style={svgStyle} />}
    </CloseIconWrapper>
  );
};

CloseButton.propTypes = {
  style: PropTypes.object,
  svgStyle: PropTypes.object,
  size: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func,
  renderIcon: PropTypes.func,
};

export default memo(CloseButton);

const CloseIconWrapper = styled.button`
  background-color: transparent;
  position: absolute;
  top: 5px;
  right: 10px;
  border: none;
  cursor: pointer;
  &:hover {
    svg {
      fill: ${({ theme }) => {
        const { alphaMod, darkGrey: _darkgrey } = theme.colors;
        return alphaMod(_darkgrey, 0.8);
      }};
    }
  }
  &:active {
    svg {
      fill: ${({ theme }) => {
        const { alphaMod, darkGrey: _darkgrey } = theme.colors;
        return alphaMod(_darkgrey, 0.5);
      }};
    }
  }
  svg {
    transition: fill 0.15s;
    fill: ${({ theme }) => theme.colors.darkGrey};
    width: 46px;
    height: 46px;
  }
`;
