/**
 * Ripspots HTTP method calls
 *
 *
 */

const isDev = process.env.NODE_ENV !== 'production';

const API_KEY = process.env.GATSBY_MAPBOX_API_KEY;

const endpoint = 'mapbox.places'; // isDev ? 'mapbox.places' : 'mapbox.places-permanent';

const BASE = 'https://api.mapbox.com';
const FORWARD_GEO = `${BASE}/geocoding/v5/${endpoint}`; // /{search_text}.json
const ACCESS_KEY = `access_token=${API_KEY}`;

const searchUrl = searchText =>
  `${FORWARD_GEO}/${searchText}.json?${ACCESS_KEY}`;

export const searchLocation = searchText =>
  new Promise((resolve, reject) => {
    const options = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const URL = searchUrl(searchText); // `${FORWARD_GEO}/${searchText}.json?${ACCESS_KEY}`;
    fetch(URL, options)
      .then(res => res.json())
      .then(res => resolve(res))
      .catch(err => reject(err));
  });
