/**
 *
 * SearchOverlay
 *
 *
 */

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Input from 'components/Elements/Input';
import Close from 'components/icons/Close';
import Search from 'components/icons/Search';

import ButtonIcon from 'components/Elements/ButtonIcon';
// import Button from 'components/Elements/Button';
// import Delete from 'components/icons/Delete';

import { sendCustomGaEvent } from 'utils/ga';

import { searchLocation } from 'utils/api/mapbox';
import { getRipspots } from 'utils/api/ripspots';
import { toastError } from 'utils/toastify';
import useOnChangeWatcher from 'utils/hooks/useOnChangeWatcher';

import Results from './Results';
// import { CloseButtonBase } from 'components/CloseButton';

const SearchOverlay = ({
  initSearch = '',
  closeMe, // ! not in use
  isOpen = false,
  onSubmit,
  onClearSearch,
  onSearchSelect,
  editInProgress,
}) => {
  const [value, setValue] = useState(initSearch);
  // const [searchResults, setSearchResults] = useState({});
  const [locationResults, setLocationResults] = useState({});
  const [ripspotResults, setRipspotResults] = useState({});
  const [localIsOpen, setLocalIsOpen] = useState(false);
  const [hasRun, setHasRun] = useState(false);

  useEffect(() => {
    if (!hasRun) return () => {}; // needs to return a fn
    if (isOpen) {
      setLocalIsOpen(true);
    } else {
      setLocalIsOpen(false);
    }
  }, [isOpen]);

  /**
   * Submits call to API for search data
   *
   * If on /map/search, lookup locations and people (ripspots)
   * if creating a ripspot, search locations only
   *
   * @param {*} evt
   */
  const handleSubmit = async evt => {
    evt.preventDefault();
    onSubmit(value);

    let resultsLength = 0;
    sendCustomGaEvent('Search', 'Search', 'Search - Search Made');
    // only fetch ripspots when not in edit mode
    if (!editInProgress) {
      try {
        const getRipspotsResponse = await getRipspots({ name: value });

        setRipspotResults(getRipspotsResponse);

        resultsLength = resultsLength + (getRipspotsResponse.length || 0);
      } catch (getRipspotsResponseError) {}
    }

    // always search Locations on map
    try {
      const searchLocationResponse = await searchLocation(value);

      // setSearchResults(searchLocationResponse);
      setLocationResults(searchLocationResponse);
      resultsLength = resultsLength + (searchLocationResponse.length || 0);
    } catch (err) {
      console.error(err);
      toastError(err.message || 'Sorry, something went wrong');
    }

    // Anayltics handling

    if (resultsLength === 0) {
      sendCustomGaEvent('Search', 'No Results', `Search - No Results`, 0);
    } else {
      sendCustomGaEvent('Search', 'Results', `Search - Results`, resultsLength);
    }
  };

  useEffect(() => {
    if (!value) {
      // clears search results
      // setSearchResults({});
      // clear locations
      setLocationResults({});
      // clear ripspots
      setRipspotResults({});
    }
  }, [value]);

  useOnChangeWatcher(initSearch, () => {
    // could be falsey ==> '', i.e. empty string to clear search
    setValue(initSearch);
  });

  /**
   * Handles search result selection
   *
   * returns native event function
   *
   * @param {*} item
   */
  const handleSearchResultClick = (item, type) => evt => {
    onSearchSelect(item, type);
  };

  //
  //
  //
  //
  // const shouldBeOpen = isOpen && localIsOpen;

  useEffect(() => {
    setHasRun(true);
  }, []);

  return (
    <Container>
      {/* <SearchWrapper>
        <ButtonIcon
          Icon={Search}
          width={22}
          height={22}
          fill="cornflowerblue"
          onClick={() => {
            
            
            setLocalIsOpen(v => !v);
          }}
        />
      </SearchWrapper> */}
      <Content isOpen={isOpen} localIsOpen={localIsOpen}>
        <Form onSubmit={handleSubmit} action="#!">
          <InputWrapper>
            <Input
              name="search"
              placeholder="Find your location..."
              type="text"
              value={value}
              onChange={evt => setValue(evt.target.value)}
            />
            <SVGWrapper
              isOpen={value !== ''}
              onClick={() => {
                setValue('');
                onSubmit('');
                onClearSearch();
              }}
            >
              <Close width="20px" height="20px" />
            </SVGWrapper>
          </InputWrapper>
          <StyledBtn type="submit" disabled={value === ''} primary>
            Search
          </StyledBtn>
        </Form>
        <Results
          // data={searchResults} // original data prop
          ripspotdata={ripspotResults} // new prop for people (ripspots)
          locationData={locationResults} // new prop for locations
          handleClick={handleSearchResultClick}
          editInProgress={editInProgress}
        />
      </Content>
    </Container>
  );
};

SearchOverlay.propTypes = {
  initSearch: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  closeMe: PropTypes.func,
  editInProgress: PropTypes.bool,
  onClearSearch: PropTypes.func.isRequired,
  onSearchSelect: PropTypes.func.isRequired,
};

export default SearchOverlay;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  left: 0;
  top: 20px;
  width: 100%;
  height: 120px;
  max-width: 650px !important;
  z-index: 99; /** Need this so popups dont overlay the search results list */

  pointer-events: none;
`;

const Content = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 95%;
  margin: 0 auto;
  opacity: 1;
  transition: opacity 0.3s;

  ${({ theme, isOpen, localIsOpen }) =>
    theme.styles.showhideStyles(localIsOpen && isOpen)};

  ${({ theme }) => theme.media.min.tablet`
    width: 75%;
  `}

  ${({ theme, localIsOpen }) =>
    !localIsOpen &&
    theme.media.max.mobile`
      opacity: 0;
      pointer-events: none;
  `}

  ${({ theme, localIsOpen, isOpen }) =>
    theme.media.min.mobile`
      ${theme.styles.showhideStyles(localIsOpen && isOpen)}}
  `}
`;

const Form = styled.form`
  background-color: #fff;
  display: flex;
  width: 95%;
  max-width: 600px;
  height: 45px;
  margin-left: 15px;
  border-radius: 5px;
  pointer-events: all;
  ${({ theme }) => theme.styles.boxShadows['2']}
  ${({ theme }) => theme.media.max.tablet`
      max-width: 300px;
  `}
  /* ${({ theme }) => theme.media.min.tablet`
      width: 75%;
  `} */
`;

const InputWrapper = styled.div`
  position: relative;
  flex: 3;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  height: 100%;
  input {
    font-size: 1rem;
    line-height: 2.5rem;
    padding-right: 35px;
    height: 100%;
    border: none;
    outline: none;
    background-color: transparent;
  }
`;

const StyledBtn = styled.button`
  background-color: ${({ theme }) => theme.colors.lightGreen};
  color: white;
  flex: 1;
  height: 100%;
  font-size: 1rem;
  border: none;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  transition: opacity 0.3s;
  cursor: pointer;
  margin-right: 0px;
  &:hover {
    opacity: 0.9;
  }
`;

const SVGWrapper = styled.div`
  position: absolute;
  top: 50%;
  right: 5px;
  transform: translateY(-50%);
  cursor: pointer;
  ${({ isOpen, theme }) => theme.styles.showhideStyles(isOpen)}
  svg {
    fill: ${({ theme }) => theme.colors.Primary};
  }
`;

const SearchWrapper = styled.div`
  width: 50px;
  opacity: 0;
  pointer-events: none;
  visibility: hidden;
  ${({ theme }) => theme.media.max.mobile`
    opacity: 1;
    visibility: visible;
    pointer-events: all;
    border: 1px solid #000;
  `}
`;
