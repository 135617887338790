/* eslint-disable react/prop-types */
/**
 *
 * Base Explore Map
 *
 *
 */

import React, { useEffect, useRef } from 'react';
import { batch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';
// import uuid from 'uuid/v4';

import MapBox from 'components/lib/Mapbox/Loadable';
import CustomMarker from 'components/lib/Mapbox/Marker/CustomMarker';
import DefaultMarker from 'components/lib/Mapbox/Marker/index';
import RipspotMarker from 'components/lib/Mapbox/Marker/Ripspot';
import CloseButton from 'components/CloseButton';
import Button from 'components/Elements/Button';
import TouchDevicePin from 'components/TouchDevicePin';
import { HEADER_HEIGHT } from 'components/Header';
import { FOOTER_HEIGHT } from 'components/Footer';

import {
  updateRipspotPin,
  setIsInExistingPinEditMode,
  setIsInNewRipspotMode,
  hydrateRipspot,
  hydrateTempData,
} from 'actions/ripspots';
import {
  // NEW
  openRipspotMemorialModal,
  closeRipspotMemorialModal,
  //
} from 'actions/modals';

import { updateRipspotHandler } from 'containers/RipspotCreation/Review/reviewHandler';

import { US } from 'utils/globals';

import { sendCustomGaEvent } from 'utils/ga';

import { CONTROLS_HEGHT } from './Controls';

import { toastInfo } from 'utils/toastify';
import { formatPinsToArray } from 'utils/helperFunctions/ripspots';
import { createRipspotHandler } from '../RipspotCreation/Review/reviewHandler';

// import { satelliteStyle } from 'utils/helperFunctions/maps';

/**
 * Tracks when we are resizing the map in console
 *
 * @param {string} ctx - context for call i.e. where it is being called from
 */
const logMapResize = ctx => {};

let dragPanEnabled;
let _isTouchDevice;
let _isHoverDevice;

let _editModePinId;
let _ripspots;
let _isFullTakeover;

const BaseMap = ({
  // mobile / touch devices
  isTouchDevice,
  isHoverDevice,
  onStyleLoad,
  isFullTakeover,
  handleMapClick,
  mapRef,
  isFirstPin,
  dispatch,
  mapState,
  setMapState,
  handleContinue,
  handleCancel,
  handleCancelAdditionalPin,
  newPinCoords,
  searchPinCoords,
  searchItem,
  selectPinIsOpen,
  setSelectPinIsOpen,
  currentPinName,
  panTo,
  newPin,
  setNewPinCoords,
  isInExistingPinEditMode,
  isViewingSingleRipspot,
  // map view mode
  // isSatellite,
  // edit/view mode
  editModePinId,
  setEditModePinId,
  setEditPinsLastCoords,
  setHasEditChanges,
  editInProgress,
  onEditRipspotClick,
  onViewRipspotClick,
  currentOpenPopupId,
  setCurrentOpenPopupId,
  handleOnDeleteMarker,
  // Semi-New
  publicRipspotPins, // 100 random RIPs
  // * NEW PROPS
  zoomTo,
  setChalllengeModalIsOpen,
  onConfirmPinLocationClick,
  user,
  // isFullPins,
}) => {
  const ripspots = useSelector(state => state.ripspots);

  const map = useRef();

  mapRef.current = map.current;

  /**
   * Handles resizing map when viewport changes
   *
   */
  const handleMapResize = () => {
    const { innerHeight, innerWidth } = window;
    const newHeight = innerHeight - HEADER_HEIGHT - CONTROLS_HEGHT;
    setMapState({
      ...mapState,
      viewport: {
        ...mapState.viewport,
        width: innerWidth,
        height: newHeight, // num,
      },
    });
  };

  /**
   * handles updating new pin's coords after a drag
   *
   * @param {*} newCoords
   */
  const handleNewPinDragCoords = newCoords => {
    setNewPinCoords(newCoords); // CAUSING MAP RESET ISSUE
    if (map.current) {
      panTo({ coords: newCoords });
      // , zoom: map.current.getZoom()
    }
  };

  /**
   * Handles updating coords and description for a given pin
   *
   * @param {*} newCoords
   * @param {*} pin
   */
  const handleUpdatePins = (pinId, newCoords, pinLabel, pinDescription) => {
    setHasEditChanges(true);
    dispatch(updateRipspotPin(pinId, newCoords, pinLabel, pinDescription));
  };

  /**
   * Handles clicks on "Edit Pin" with given pin's Id passed in props
   *
   * @param {string} pinId
   */
  const handleOnEditPinClick = pinId => evt => {
    const coords = ripspots.pins[pinId].pinCoords;
    setEditModePinId(pinId);
    setEditPinsLastCoords(coords);

    if (isTouchDevice) {
      setTimeout(() => {
        panTo({ coords });
        // , zoom: mapState.viewport.zoom
      }, 300);
    }
  };

  const hydrateRipspotAfterUpdate = ripspotRes => {
    batch(() => {
      dispatch(hydrateRipspot(ripspotRes));
      dispatch(hydrateTempData(ripspotRes));
      dispatch(setIsInExistingPinEditMode(true));
      dispatch(setIsInNewRipspotMode(false));
    });
  };

  /**
   * Handles saving a pins new location
   *
   * @param {*} pinId
   */
  const handleOnSaveClick = (pinId, pinCoords, pinLabel, pinDescription) => {
    const updatedRipspots = {
      ...ripspots,
      pins: {
        ...ripspots.pins,
        [pinId]: {
          ...ripspots.pins[pinId],
          pinCoords,
          pinLabel,
          pinDescription,
        },
      },
    };

    setEditModePinId('');
    setEditPinsLastCoords([]);
    selectPinIsOpen && setSelectPinIsOpen(false);
    setNewPinCoords([]); // was US

    if (ripspots.isInNewRipspotMode) {
      const toastId = toastInfo('Creating ripspot...', {
        autoClose: true,
      });
      const { latitude, longitude } = mapState.viewport;
      const formattedPins = formatPinsToArray(updatedRipspots.pins);
      const newRipspot = {
        name: '',
        pins: JSON.stringify(formattedPins),
        location: {
          name: '',
          latitude,
          longitude,
        },
        isPublic: true,
        isActive: true, // TODO: allow user to update this value
        isComplete: false,
        payment: {
          paymentTier: 'basic',
          paymentTerm: 'annual',
        },
      };
      createRipspotHandler({ newRipspot, dispatch, toastId, user }).then(
        ripspotRes => {
          hydrateRipspotAfterUpdate(ripspotRes);
        },
      );
    } else {
      const toastId = toastInfo('Updating ripspot...');
      updateRipspotHandler({
        dispatch, // done
        ripspotData: updatedRipspots.tempData, // done
        user, // done
        ripspots: updatedRipspots, // done
        toastId,
      }).then(ripspotRes => {
        hydrateRipspotAfterUpdate(ripspotRes);
      });
    }
  };

  const preventDrag = () => {
    if (map.current) {
      map.current.dragPan.disable();
      map.current.dragPan.enable();
    }
  };

  // issue with react state vars passed into callbacks only receiving the first value
  // need to use vars declared outside render cycle
  dragPanEnabled = isTouchDevice || isHoverDevice;
  _isTouchDevice = isTouchDevice;
  _isHoverDevice = isHoverDevice;

  _editModePinId = editModePinId;
  _ripspots = ripspots;
  _isFullTakeover = isFullTakeover;

  /**
   * Handles touch devices / pointer device map nav controls
   *
   * @param {*} mapEvt
   * @param {*} { originalEvent }
   * @returns
   */
  const handleDragStart = (mapEvt, { originalEvent }) => {
    if (!dragPanEnabled) {
      // prevent any action until we have either hover or touch events
      // prevents initial single touch map movement on mobile if refreshing page on map itself
      preventDrag();
      return;
    }

    //
    if (!_isTouchDevice) {
    } else if (_isTouchDevice && !_isFullTakeover) {
      // if in takeover screen mode, allow single swipe
      // Touch Devices!! We need to cancel single finger swipes so vertical page scrolling is unaffected
      if (
        originalEvent &&
        'touches' in originalEvent &&
        originalEvent.touches.length >= 2
      ) {
        isTwoFingers = true;
      } else {
        isTwoFingers = false;
        // preventDrag();
      }
    }
  };

  /**
   * Handles updating map on dragend and the currently edited pin's location
   * if user is on touch device
   *
   * @param {*} currentMap
   * @param {*} { originalEvent }
   */
  const handleDragEnd = (currentMap, { originalEvent }) => {
    const newMapCenter = currentMap.getCenter();
    const newCenterCoords = [newMapCenter.lng, newMapCenter.lat];
    //
    // need to update the initial coords so we retain center on style change which re-initializes the map according to these init props
    // - I dont think we need to adjust zoom level here since we only are dragging at the current zoom level
    //
    panTo({ coords: newCenterCoords });
    // update map location
    // updateMap(currentMap);

    if (_isTouchDevice && _editModePinId) {
      // need to "save" coords right away
      const center = currentMap.getCenter();
      const pin = _ripspots.pins[_editModePinId];
      // automatically update
      handleUpdatePins(pin.id, [center.lng, center.lat]); // Touch Devices ONLY!!
    }
  };

  const syncReactMapState = _mapInstance => {
    const newMapCenter = _mapInstance.getCenter();
    const newMapZoom = _mapInstance.getZoom();
    const newCenterCoords = [newMapCenter.lng, newMapCenter.lat];
    //
    // need to update the initial coords && the ZOOM so we retain center and zoom level on style change which re-initializes the map according to these init props
    //
    panTo({ coords: newCenterCoords, zoom: newMapZoom });
  };

  /**
   * currently using this to handle both zoom and drag map updates to
   * keep react state in sync with web-GL layer
   *
   * @param {*} currentMap
   */
  const handleOnMoveEnd = currentMap => {
    syncReactMapState(currentMap);
  };

  const handleOnZoomEnd = currentMap => {
    syncReactMapState(currentMap);
  };

  // props for the NEW ripspot pin
  const newPinProps = {
    dispatch,
    isFirstPin,
    handleCancelAdditionalPin,
    newMarker: true,
    showPopup: true, // initialy shows popup
    // showPoint
    draggable: true,
    id: 'create-ripspot-pin',
    coords: newPinCoords,
    updatePin: handleNewPinDragCoords,
    // setChalllengeModalIsOpen(true);
    onSaveClick: handleContinue(true, true), // this provides a curried inner function in ExploreMap/index.js
    onEditPinClick: handleContinue(false), // this provides a curried inner function in ExploreMap/index.js
    currentPinName,
    // Edit Mode
    editInProgress,
    onEditRipspotClick,
    onViewRipspotClick,
    // handles which pin's popup is open
    currentOpenPopupId,
    setCurrentOpenPopupId,
    // * NEW Props
    zoomTo,
  };

  let mapHeight;
  let mapWidth = '100%';

  if (editModePinId || newPin) {
    mapHeight = `calc(100vh - ${FOOTER_HEIGHT})`;
    mapWidth = '100vh';
  } else {
    mapHeight = `calc(100vh - ${HEADER_HEIGHT}px - ${CONTROLS_HEGHT}px)`;
  }

  useEffect(() => {
    if (editModePinId && map.current) {
      // going into or out of "edit" and "new pin" modes, the map changes dimensions on touch devices
      // full screen takeover for edit mode
      // this clears up any whitespace that might occur from map height/width changes
      // not propagating to WebGL layer

      // This is an old code piece, from above old comments, we may want tio run this if touch device otherwise it causes zoom annoyance on desktop
      map.current.resize();
      return;
    }

    if (newPin && map.current) {
      // going into or out of "edit" and "new pin" modes, the map changes dimensions on touch devices
      // full screen takeover for edit mode
      // this clears up any whitespace that might occur from map height/width changes
      // not propagating to WebGL layer

      // This is an old code piece, from above old comments, we may want tio run this if touch device otherwise it causes zoom annoyance on desktop
      map.current.resize();
    }
  }, [editModePinId, newPin, map.current]);

  const showSearchPin = searchPinCoords && searchPinCoords.length > 0;

  const { currentRipspot } = ripspots;

  //
  // only show public pins when NOT editing/viewing/searching ripspots
  //
  let showRandomPins =
    !editInProgress &&
    !showSearchPin &&
    !currentRipspot &&
    publicRipspotPins &&
    Object.keys(publicRipspotPins).length > 0;

  if (typeof showRandomPins === 'undefined') {
    // hack for now
    showRandomPins = [];
  }

  return (
    <MapBox
      mapState={mapState}
      height={mapHeight}
      width={mapWidth}
      isFullScreen={!!(editModePinId || newPin)}
      // isSatellite={isSatellite}
      onClick={handleMapClick}
      onDblClick={() => {}}
      onResize={handleMapResize}
      onDragEnd={handleDragEnd}
      onDragStart={handleDragStart}
      onZoomEnd={handleOnZoomEnd}
      onMoveEnd={handleOnMoveEnd}
      onStyleLoad={_map => {
        map.current = _map;
        onStyleLoad && onStyleLoad();
      }}
    >
      <>
        {/**
         *
         *
         * Public (active) Pins
         *
         *  */}
        {showRandomPins &&
          Object.keys(publicRipspotPins).map(pinId => {
            const pin = publicRipspotPins[pinId];
            const { pinId: publicPinId, pinCoords, pinName, ripspot } = pin;
            return (
              <CustomMarker
                key={publicPinId}
                pinName={pinName}
                coords={pinCoords}
                ripspot={ripspot || {}} // this avoided a console error from an earlier commit
                isTouchDevice={isTouchDevice}
                isHoverDevice={isHoverDevice}
                handleOnClick={
                  isHoverDevice &&
                  (evt => {
                    dispatch(
                      openRipspotMemorialModal({
                        metaData: {},
                        ripspotId: ripspot.id,
                      }),
                    );
                    sendCustomGaEvent(
                      'Search',
                      'View Ripspot Modal',
                      `Search - View Ripspot Modal ${ripspot.id}`,
                    );
                  })
                }
              />
            );
          })}

        {/**
         *
         *
         * SEARCH Pin
         *
         *  */}
        {showSearchPin && (
          <DefaultMarker
            coords={searchPinCoords}
            isTouchDevice={isTouchDevice}
            renderPopup={({ setIsOpen }) => (
              <PopupContent>
                <p>{searchItem.place_name || searchItem.petName || 'N/A'}</p>
                {searchItem.petName && searchItem.breed && (
                  <p>{searchItem.breed}</p>
                )}
                <CloseButton size={25} onClick={() => setIsOpen(false)} />
                {editInProgress && !isTouchDevice && (
                  <Button
                    primary
                    style={{ marginTop: 10 }}
                    onClick={evt =>
                      onConfirmPinLocationClick(evt, searchPinCoords)
                    }
                  >
                    Confirm
                  </Button>
                )}
              </PopupContent>
            )}
            showPoint
          />
        )}

        {/**
         *
         *
         * Existing pins
         *
         *  */}

        {ripspots.pins &&
          Object.keys(ripspots.pins).length > 0 &&
          Object.keys(ripspots.pins).map((pinId, index) => {
            const pin = ripspots.pins[pinId];
            // ripspot used for editing
            const editRipspot =
              ripspots &&
              ripspots.publicRipspots &&
              pin.ripspotId in ripspots.publicRipspots &&
              ripspots.publicRipspots[pin.ripspotId];

            const isCurrentEditPin = editModePinId === pinId;
            // const isTouchEdited = isCurrentEditPin && isTouchDevice;
            const isTouchEdited = false;
            const disabled =
              (editModePinId && editModePinId !== pinId) || false;

            return isTouchEdited ? (
              // touch device full screen takeover
              <TouchDevicePin
                {...newPinProps} // need these for pin popup
                newMarker={false}
                dispatch={dispatch}
                currentPinName={pin.pinName}
                key={pinId}
                editModePinId={editModePinId}
                pin={pin}
              />
            ) : (
              // Existing pins
              <RipspotMarker
                // showPoint
                key={pinId}
                id={pinId}
                coords={pin.pinCoords}
                pin={pin}
                ripspot={isCurrentEditPin ? editRipspot : currentRipspot} // feed correct data depending on state, edit or viewing
                dispatch={dispatch}
                pinIndex={pin.pinIndex || index}
                draggable={isCurrentEditPin} // only allow draggable if user clicks on "edit location"
                currentPinName={pin.pinName} // name of the pin icon to display
                isViewingSingleRipspot={isViewingSingleRipspot}
                disabled={disabled} // if we are placing a new pin on map or editing existing pin, disable the other pins
                onEditPinClick={handleOnEditPinClick(pinId)}
                onSaveClick={handleOnSaveClick}
                onCancelClick={handleCancel}
                handleCancelAdditionalPin={handleCancelAdditionalPin}
                // updates this pins new coords
                updatePin={(id, newCoords, pinLabel, pinDescription) => {
                  //
                  // onPinDragEnd
                  //
                  handleUpdatePins(id, newCoords, pinLabel, pinDescription); // for hover devices (anything that is non-touch device)
                }}
                // Edit Mode
                editInProgress={editInProgress}
                isInExistingPinEditMode={isInExistingPinEditMode}
                onEditRipspotClick={onEditRipspotClick}
                onViewRipspotClick={onViewRipspotClick}
                currentOpenPopupId={editModePinId}
                setCurrentOpenPopupId={setCurrentOpenPopupId}
                onDeleteMarker={markerId => {
                  handleOnDeleteMarker(markerId);
                }}
                // * NEW Props
                setZoom={zoomTo}
                isTouchDevice={_isTouchDevice}
                isHoverDevice={_isHoverDevice}
                handleOnClick={
                  isHoverDevice &&
                  (() => {
                    dispatch(
                      openRipspotMemorialModal({
                        metaData: {},
                        ripspotId: currentRipspot && currentRipspot.id,
                      }),
                    );
                  })
                }
              />
            );
          })}
      </>
    </MapBox>
  );
};

BaseMap.propTypes = {
  isTouchDevice: PropTypes.bool.isRequired,
  isHoverDevice: PropTypes.bool.isRequired,
  onStyleLoad: PropTypes.func,
  //
  isFullTakeover: PropTypes.bool.isRequired,
  mapRef: PropTypes.any,
  handleContinue: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
  ripspotModal: PropTypes.shape({
    isOpen: PropTypes.bool,
  }),
  panTo: PropTypes.func.isRequired,
  // * NEW  PROPS \/
  zoomTo: PropTypes.func.isRequired,
  isFirstPin: PropTypes.bool.isRequired,
  setChalllengeModalIsOpen: PropTypes.func.isRequired,
  handleCancelAdditionalPin: PropTypes.func.isRequired, // used to delete extra pins whgen dragging new additional one, but stays in edit mode
  // * NEW        /\
  mapState: PropTypes.shape({
    style: PropTypes.string,
    viewport: {
      width: PropTypes.number,
      height: PropTypes.number,
      longitude: PropTypes.number,
      latitude: PropTypes.number,
      zoom: PropTypes.number,
      maxZoom: PropTypes.number,
    },
  }),
  setMapState: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  newPinCoords: PropTypes.any.isRequired,
  setNewPinCoords: PropTypes.func.isRequired,
  editModePinId: PropTypes.string.isRequired,
  setEditModePinId: PropTypes.func.isRequired,
  setEditPinsLastCoords: PropTypes.func.isRequired,
  selectPinIsOpen: PropTypes.bool.isRequired,
  setSelectPinIsOpen: PropTypes.func.isRequired,
  editInProgress: PropTypes.bool.isRequired,
  // Current PIN
  newPin: PropTypes.any,
  // edit/view ripspot
  onEditRipspotClick: PropTypes.func.isRequired,
  onViewRipspotClick: PropTypes.func.isRequired,
  setHasEditChanges: PropTypes.func.isRequired,
  // current pin popup open
  currentOpenPopupId: PropTypes.string.isRequired,
  setCurrentOpenPopupId: PropTypes.func.isRequired,
  handleOnDeleteMarker: PropTypes.func.isRequired,
  //
  handleMapClick: PropTypes.func.isRequired,
};

export default BaseMap;

const PopupContent = styled.div`
  color: #000;
  padding: 15px;
  max-width: 300px;
  max-height: 200px;
  font-size: 0.8rem;

  p {
    font-size: 1rem;
  }
`;

// /**
//  * Handles touch devices / pointer device map nav controls
//  *
//  * @param {*} mapEvt
//  * @param {*} { originalEvent }
//  * @returns
//  */
// const handleDragStart = useCallback(
//   (mapEvt, { originalEvent }) => {
//     if (!dragPanEnabled) {
//       // prevent any action until we have either hover or touch events
//       // prevents initial single touch map movement on mobile if refreshing page on map itself
//       preventDrag();
//       return;
//     }

//     //
//     if (!_isTouchDevice) {
//
//
//     } else if (_isTouchDevice && !_isFullTakeover) {
//       // if in takeover screen mode, allow single swipe
//       // Touch Devices!! We need to cancel single finger swipes so vertical page scrolling is unaffected
//       if (
//         originalEvent &&
//         'touches' in originalEvent &&
//         originalEvent.touches.length >= 2
//       ) {
//
//
//         isTwoFingers = true;
//       } else {
//         isTwoFingers = false;
//         // preventDrag();
//
//
//       }
//     }
//   },
//   [dragPanEnabled],
// );
