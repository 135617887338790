/**
 *
 * Location
 *
 *
 */

import React from 'react';
import { SVGPropTypes } from 'utils/propTypes';

export const Location = ({
  fill,
  width = '100%',
  height = '100%',
  stroke = '',
  style = {},
}) => (
  <svg height={height} width={width} style={style} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 84.18 129.93">
    <g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1">
      <path d="M42.1,0h0C18.77.36-.62,19.35,0,42.71c.72,26.36,39.08,82.83,42.07,87.18v0c3-4.35,41.35-60.82,42.06-87.18C84.79,19.35,65.4.36,42.1,0Z" fill="#8dc63f"/>
      <circle cx="42.22" cy="42.09" r="19.25" fill="#fff"/>
      </g>
    </g>
  </svg>
);

Location.propTypes = SVGPropTypes;

export default Location;
