/**
 *
 * CUSTOM Challlenge Modal
 *
 *
 */

import React, { memo, useState } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';

// mikeschmerbeck@gmail.com

import Button from 'components/Elements/Button';
import Modal from 'components/modals/index';

const AdditionalPinModal = props => {
  const {
    prompt,
    confirmPrompt, // a shorter string for button text
    onConfirm,
    onDeny,
    isOpen,
    onRequestClose,
    positive = false,
    denyPrompt = '',
    isAdditionalPinModalOpen,
    customPrompt,
  } = props;

  return (
    <Modal
      closeOnOutsideClick
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      closeIconSize="md"
      title=""
    >
      <Container>
        <Wrapper>
          <Title>
            {prompt || customPrompt ? `${customPrompt}` : 'Are you sure?'}
          </Title>
          <Controls>
            <Button
              style={{ marginRight: '25px' }}
              primary={!positive}
              onClick={evt => {
                onDeny && onDeny(evt);
                onRequestClose(evt);
              }}
            >
              {denyPrompt || 'No'}
            </Button>
            <Button
              dangerous={!positive}
              primary={positive}
              onClick={onConfirm}
            >
              {confirmPrompt ? `${confirmPrompt}` : 'Yes'}
            </Button>
          </Controls>
        </Wrapper>
      </Container>
    </Modal>
  );
};

AdditionalPinModal.propTypes = {};

const mapStateToProps = ({ display, modals }) => ({
  display,
  isOpen: modals.additionalPinModal.isOpen,
});

const withConnect = connect(mapStateToProps);

export default compose(memo, withConnect)(AdditionalPinModal);

const Container = styled.div`
  ${({ theme }) => theme.styles.middle}
`;
const Wrapper = styled.div`
  ${({ theme }) => theme.styles.boxShadows['0']};
  background-color: #fff;
  padding: 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Controls = styled.div`
  display: flex;
  justify-content: space-around;
  margin-top: 55px;

  button {
    min-width: 140px;
  }
`;

const Title = styled.h2`
  font-size: 1.4rem;
`;
