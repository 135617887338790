/* eslint-disable prefer-destructuring */
/**
 *
 * ExploreMap
 *
 *
 */

import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import styled, { css, createGlobalStyle } from 'styled-components';
import uuid from 'uuid/v4';
import { navigate } from 'gatsby';
// import * as MapboxGl from 'mapbox-gl';
// import { toastError } from 'utils/toastify';

import { HEADER_HEIGHT } from 'components/Header';
// import Button from 'components/Elements/Button';
import SelectPinModal from 'components/modals/SelectPinModal';
import OnBoardingModal from 'components/modals/OnBoardingModal';
import ChallengeModal from 'components/modals/ChallengeModal';
import AdditionalPinModal from 'components/modals/AdditionalPinModal';
import RipspotMemorialModal from 'components/modals/RipspotMemorialModal';
// import openChallengeModal from 'components/modals';

import {
  openRipspotModal,
  openOnBoardingModal,
  closeOnBoardingModal,
  openAdditionalPinModal,
  closeAdditionalPinModal,
} from 'actions/modals';

import { updateRipspotHandler } from 'containers/RipspotCreation/Review/reviewHandler';

import {
  addRipspotPin,
  removeRipspotPin,
  updateRipspotPin,
  setIsInExistingPinEditMode,
  setEditInProgress,
  resetTempRipspot,
  resetRipspotPins,
  hydrateTempData,
  updateRipspotPinsName,
  // fetchAndSetBasePins,
  setPublicPins,
  // addPublicPin,
  hydrateRipspot,
  removeCurrentRipspot,
  setIsInNewRipspotMode,
  setViewSingleRipspot,
  setBoundPins,
} from 'actions/ripspots';
// import { keyBy } from 'utils/helperFunctions/tools';
import {
  getMapBounds,
  streetStyle,
  satelliteStyle,
} from 'utils/helperFunctions/maps';
// import Theme from 'utils/theme';
import { US } from 'utils/globals';
import { MAP_CR_DETAILS_ROUTE, MAP_SEARCH_ROUTE } from 'utils/routes';
// import { getRipspots } from 'utils/api/ripspots';
// import { MAX_ZOOM_IN } from 'utils/constants';
//
import Controls from './Controls';
import SearchOverlay from './SearchOverlay';
import TopMapNav from './TopMapNav';
import BaseMap from './BaseMap';
// local apis
import shouldShowRandomPins from './shouldShowRandomPins';

export const CONTROLS_HEIGHT = 65;

// Mapbox zoom max level - all the way zoomed in
export const MAX_ZOOM_IN = 13;

// const World = [-50.661037978865124, 27.429207593202875];
// Starbucks, Doylestown PA
// const point = [-75.130841, 40.309922];

//
// not sure neeeded, but I moved to outside render cycle possibly to feed into some callbacks which would only have the original react value since no render/repaints may have occured to update value in callback --> this trick or using a ref is besrt practice, when using useCallback I have found --> check BaseMap.js
//
let mapCoords = [];

const GlobalStyle = createGlobalStyle`
  .mapboxgl-canvas-container { 
    /* // super important!!! show crosshair so hand "grab" doesnt block view */
    /* cursor: crosshair !important; */
    ${({ useCrosshair }) =>
      useCrosshair &&
      css`
        cursor: crosshair !important;
      `}
  }
  .mapboxgl-ctrl-logo {
    pointer-events: none !important;
    width: 60px !important;
    background-size: cover !important;
    height: 15px !important;
    margin-bottom: 6px !important;
  }
  ${({ useCrosshair, isMobile }) =>
    useCrosshair &&
    isMobile &&
    css`
      body {
        background-color: ${({ theme }) => theme.colors.Primary};
      }
    `}
`;

/**
 * Explore Map
 *
 * Two main methods that control the map flow UX:
 *  - handleContinue
 *  - handleMapClick
 *
 * @param {*}
 * @returns
 */
const ExploreMap = ({
  isTouchDevice,
  isHoverDevice,
  onBoardingModal,
  ripspotModal,
  additionalPinModal,
  ripspotData,
  isViewingSingleRipspot,
  dispatch,
  user,
  location,
  boundRipspotPins,
  // dimensions,
}) => {
  // map needs:
  //  1) Edit modality
  //  2) View Modality

  // grab some important props
  const {
    editInProgress,
    isInNewRipspotMode,
    isInExistingPinEditMode, // === isInExistingPinEditMode
    publicRipspotPins,
    publicRipspots,
    pins,
    viewSingleRipspot,
  } = ripspotData;

  // * **************************************************************
  // * Ripspot Creation flow
  // * Action 1
  // *  - using this to explain and refresh memories of what props in this file do as it is quite "loaded" lolol
  // *
  // *  IMPORTANT!!!
  // * `editInProgress` ==> tells the map "okay lets start creating a rispot" or "editing previous ripspot is in progress"
  // *
  // * `newPin` ==> This is the new pin current being placed...just the pin style obj attributes I believe
  // * `newPinCoords` ==> coords to place this `newPin` which is a Ripspot Pin aka a Layer with a Feature aka the Pin itself --> not using actual Mapbox-gl "Marker"s for our Pins
  // *
  // * `publicRipspots` ==> double check but pretty sure data for pins when we click on them.
  // * `mapStyleReady` ==> fires once the root base map has styles applied post mounting
  // *
  // *
  // *
  // *
  // * **************************************************************

  // old local EditMode indicator, we want to store this in redux for going to and from account and map during an edity
  // const [editInProgress, seteditInProgress] = useState(editInProgress);
  // this flag just tells continue button to return to "Review" step of checkout meaning user clicked "Edit Pins" from there
  // const [isInExistingPinEditMode, setIsInExistingPinEditMode] = useState(false);

  // *
  // *
  // *
  // *  NEW!!!
  // *
  // NEW
  // if the map can be droppable and pins disabled
  // const [isDroppingNewPin, setIsDroppingNewPin] = useState(false); // not sure if needed!!!!!
  // *
  // *
  // * notice 3 L's......first challenege modal got hardcoded...leave for now....revisit later to refactor into correct spelling and fix other "challenge" modal which is tied to ripspots
  const [inViewMode, setInViewMode] = useState(false);
  // *
  // *
  // *

  // TODO these will be global vars !!!!!!!!!!!! --> not sure
  // currently only pins are global in redux
  // const [publicRipspots, setPublicRipspots] = useState([]);
  // const [publicRipspotPins, setPublicRipspotPins] = useState([]);

  const [mapStyleReady, setMapStyleReady] = useState(false);
  // right now this is places via Google Maps, but will also be for People/Pets
  const [search, setSearch] = useState('');
  // this is for opening and closing the select pin style modal
  const [selectPinIsOpen, setSelectPinIsOpen] = useState(false);

  const [hasEditChanges, setHasEditChanges] = useState(false);
  // TODO:  NOT IN USE
  const [searchIsOpen, setSearchIsOpen] = useState(false);

  const openPinModal = _ripspotData => {
    setSelectPinIsOpen(true);
  };
  const closePinModal = () => setSelectPinIsOpen(false);

  // currently editable/draggable already existing pin
  const [editModePinId, setEditModePinId] = useState(null);
  // used to cancel and revert coords of pin we are editing aka dragging
  const [editPinsLastCoords, setEditPinsLastCoords] = useState([]);

  // current NEW editable/draggable pin
  const [currentPinName, setCurrentPinName] = useState('');
  const [newPin, setNewPin] = useState(null);
  const [newPinCoords, setNewPinCoords] = useState([]);

  // search results pin
  const [searchPinCoords, setSearchPinCoords] = useState([]);
  const [searchItem, setSearchItem] = useState(null);
  const [touchInitiated, setTouchInitiated] = useState(false);

  const [currentOpenPopupId, setCurrentOpenPopupId] = useState('');

  const mapRef = useRef();

  //
  // provide some initial values for map viewport
  // we let the map handle changes after setting initial value
  // to avoid jankiness and unwanted zooms/pans
  //
  const [mapState, setMapState] = useState({
    style: streetStyle, // this drives which style map to be rendered
    viewport: {
      width: window.innerWidth,
      height: window.innerHeight,
      longitude: US[0],
      latitude: US[1],
      zoom: 3.5, // 1 is the entire map from end to end perfectly aligned (I think unless monitor too large)
      maxZoom: MAX_ZOOM_IN, // 13, CLOSE in to map
    },
  });

  useEffect(() => {
    if (!editInProgress) {
      //

      // these get cached in redux // TODO: timestamnp to update random ones?
      shouldShowRandomPins({
        dispatch,
        setPublicPins,
        limit: 100,
        query: {}, // optioinal
      });
    }
  }, []);

  useEffect(() => {
    if (!editInProgress) {
      setCurrentPinName('');
    }
  }, [editInProgress]);

  useEffect(() => {
    if (touchInitiated) {
      beginTouchPinPlacement();
    }
  }, [mapStyleReady]);

  /**
   * Clears current map data, sets edit modes and opens
   * pin modal
   *
   * in selection map
   *
   */
  const handleCreateNewRipspot = offPage => {
    if (Object.keys(ripspotData.pins).length) {
      // remove any pins from the map to prepare for edit mode
      // TODO: save these for adding back to map later?
      dispatch(resetRipspotPins());
    }
    // remove tempData
    dispatch(resetTempRipspot());
    setCurrentPinName('');
    // set edit mode
    dispatch(setEditInProgress(true));
    dispatch(setIsInNewRipspotMode(true));
    // *
    // * START!
    // *
    // * Need to first open the "On-boarding step"
    // *
    // *
    if (isTouchDevice && mapStyleReady) {
      beginTouchPinPlacement();
      setTouchInitiated(true);
    } else if (isTouchDevice) {
      setTouchInitiated(true);
      // TODO need to wait for mapStyleReady somehow
    }
    dispatch(openOnBoardingModal());
  };

  /**
   * Bounds the map around pins!
   *
   * @param {*} ripspotPins
   * @returns
   */
  const setMapBounds = ripspotPins => {
    if (!ripspotPins) return;

    if (ripspotPins.length === 1) {
      const onlyPin = ripspotPins[0];
      // set bounds with padding
      mapRef.current.flyTo({ center: onlyPin.pinCoords });
    } else if (ripspotPins.length > 1) {
      const bounds = getMapBounds(ripspotPins, dispatch);
      // set bounds with padding
      const paddingAmount = window && window.innerWidth * 0.1;

      mapRef.current.fitBounds(bounds, { padding: paddingAmount });
    }
  };

  useEffect(() => {
    if (isInExistingPinEditMode) {
      // get pinName and set it

      if (Object.keys(pins).length > 0) {
        const firstPinKey = Object.keys(pins)[0];
        const firstPin = pins[firstPinKey];
        const pinName = firstPin.pinName;
        setCurrentPinName(pinName);
      }
    }
  }, [isInExistingPinEditMode]);

  useEffect(() => {
    if (ripspotData.currentRipspot && boundRipspotPins) {
      setMapBounds(boundRipspotPins);
      dispatch(setBoundPins(null));
    }
  }, [boundRipspotPins]);

  // ___________________________________________________________________________
  //
  //
  // Check for NEW ripspot from route state
  //
  // ___________________________________________________________________________

  useEffect(() => {
    const routeState = location.state;
    // createNewRipspot
    //
    // handles all router-state actions
    //
    // this handles redirects from anywhere a user clicks "create ripspot"
    if (
      routeState &&
      'createNewRipspot' in routeState &&
      routeState.createNewRipspot &&
      !('viewDefaultRipspots' in routeState) // *A) A new ripspot being created
    ) {
      // create ripspot
      setTimeout(() => {
        // need to delay slightly, otherwise modal does not open and controls are also hidden, locked state
        handleCreateNewRipspot(true); // call create handler for major lifting
        // NEW!!! we needed this to handle the case where this call is not set in another action
        dispatch(setEditInProgress(true)); // this tell UI when coming from a route change we are editing
        setCurrentPinName('');
      }, 500);
    }
  }, []);

  // ___________________________________________________________________________
  //
  //
  //   Edit an EXISTING ripspot from route state
  //
  // ___________________________________________________________________________
  useEffect(() => {
    const routeState = location.state;
    if (
      routeState &&
      'isInExistingPinEditMode' in routeState && // in edit mode
      !routeState.isInExistingPinEditMode //  *B) now in edit mode from existing ripspot ()possibly set from when new rispot, need to double check)
    ) {
      dispatch(setIsInExistingPinEditMode(true));
    }
    // eslint-disable-next-line react/prop-types
  }, [location]);

  // ___________________________________________________________________________
  //
  //
  // Determines if map should bound to current pins rendered so that all are
  // visible regardless of location on globe
  //
  // ___________________________________________________________________________

  useEffect(() => {
    // help differentiate between redux and local state versus redux navigate route state vars passed on page chnage
    const routeState = location.state;

    // handles setting map bounding box
    if (mapStyleReady && mapRef.current) {
      // ------------------------------------------------
      // EDIT Ripspot
      // ------------------------------------------------
      // * this handles redirects from Account page editing a ripspot
      if (routeState && 'isEditMode' in routeState && routeState.isEditMode) {
        if (ripspotData.currentRipspot) {
          dispatch(setEditInProgress(routeState.isEditMode));
          dispatch(setIsInExistingPinEditMode(true));
          dispatch(setIsInNewRipspotMode(false));
          const ripspotPins = ripspotData.currentRipspot.pins;
          // sets map to bound pins so that all our visible
          setMapBounds(ripspotPins);
        }
      }

      // ------------------------------------------------
      // should show 100 default ripspot pins
      // ------------------------------------------------
      if (
        routeState &&
        'viewDefaultRipspots' in routeState &&
        routeState.viewDefaultRipspots
      ) {
        if (!ripspotData.currentRipspot) {
          if (!routeState.isEditMode) {
            dispatch(setEditInProgress(false));
          }
          setInViewMode(true);
          // const ripspotPins = routeState.viewDefaultRipspots;
          //
          //
          //
          //
          // // TODO: use action that expects id's keyed objs
          // setPublicRipspotPins(ripspotPins);
        }
      }

      if (routeState && 'viewRipspot' in routeState && routeState.viewRipspot) {
        // TODO: might need to make sure this is not true when displaying 100 with selected ripspot pins
        if (ripspotData.currentRipspot) {
          if (!routeState.isEditMode) {
            dispatch(setEditInProgress(false));
          }
          // TODO: Add code here to tell baseMap that we are showing currentRipspot
        }
      }

      // ------------------------------------------------
      // shouldBoundPins (when not vviewing normally) *
      // * this must come last for logic purposes after clearing map
      // ------------------------------------------------
      if (
        routeState &&
        'shouldBoundPins' in routeState &&
        routeState.shouldBoundPins // Bound pins around box so ALL are visible
      ) {
        if (ripspotData.currentRipspot) {
          const ripspotPins = ripspotData.currentRipspot.pins;
          setMapBounds(ripspotPins);
        }
      }
    }
  }, [mapStyleReady, mapRef.current, location.pathname]);

  /**
   * This is just for setting the initialValues for next react-render cycle
   * so our react state stays in sync with the Mapbox Web-GL data layer.
   * We now use the Map instyance method 'map.flyTo({...})'
   *
   * @param {[]} { coords}
   */
  const panTo = ({ coords, zoom }) => {
    //
    //
    //
    //

    const newMapState = {
      ...mapState,
      viewport: {
        ...mapState.viewport,
        longitude: coords[0],
        latitude: coords[1],
      },
    };

    if (zoom) {
      //
      newMapState.viewport.zoom = zoom;
    }
    // set the state for map! keeps map in sync with initValues next time the map component re-renders
    setMapState(newMapState);
  };

  const toggleAdditionalPinModal = state => {
    if (state) {
      dispatch(openAdditionalPinModal());
    } else {
      dispatch(closeAdditionalPinModal());
    }
  };

  /**
   *
   *
   * @param {*} newZoomLevel
   */
  const zoomTo = newZoomLevel => {
    //
    //
    //
    const currentZoom = mapState.viewport.zoom;
    if (currentZoom !== newZoomLevel) {
      setMapState({
        ...mapState,
        viewport: {
          ...mapState.viewport,
          zoom: newZoomLevel,
        },
      });
    } else {
    }
  };

  // to determine if we want to be able to delete or cancel a pin
  const pinsKeysLen = Object.keys(ripspotData.pins).length;
  const isFirstPin = pinsKeysLen === 0;

  /**
   * Opens the Create Ripspot modal
   *
   */
  const handleOpenRipspotModal = () => {
    const newRipspot = {
      metadata: {
        pinName: currentPinName,
        mapState,
        isInNewRipspotMode,
        isInExistingPinEditMode,
      },
    };
    // open Create Rispot modal
    dispatch(openRipspotModal(newRipspot));
  };

  // * **************************************************************
  // *
  // * BEGIN Handle Continue
  // *
  // * **************************************************************

  /**
   *
   * handleContinue
   *
   * This is a callback from both the pick pin modal and the bottom control bar continue button
   * Also used in Challlenege modal
   *
   *
   * @param {boolean} [openModal=true]
   * @param {boolean} [createPin=false] // This used to be true!!
   *
   * used to always create pins by default,
   * but this causes a bug with adding pins when we dont click on the map...
   */
  const handleContinue = (openModal = true, createPin = false) => evt => {
    //
    // This next if(newPin) {...} statement might not be needed in the end!
    // This is causing a bug when we click the "continue" button
    // in ExploreMap's Control Component where it adds new pins
    // to data even though we dont click map and they never render on map
    // I think because they are placed at the same coords as the previous new pin
    // we cant tell there is more than one pin there
    // TODO: look into how to handle rendering pins that are all at the same relative location
    //

    if (newPin) {
      // *
      // * maybe only need this block for the first pin or for touch device UX only?
      // *

      //  _________________________________________________________________________________________
      //
      // Handle control of when we have a NEW Pin being placed
      //
      //  _________________________________________________________________________________________

      // ------------ New pin
      let pinCoords = newPinCoords;

      if (isTouchDevice) {
        // if touch device, we need to use the current center of the map to place the pin the correct location
        if (mapRef.current) {
          // get map coords
          const map = mapRef.current;
          const center = map.getCenter();
          pinCoords = [center.lng, center.lat];
        }
      }
      // TODO: Do we want this in future?  a modal to ask to add another pin or continue? or ditch it?
      // // open pin modal to drop another pin or continue
    }
    // ------------ End New pin

    // *
    // * The following code is needed for continuing! Runs every time
    // *
    //  _________________________________________________________________________________________

    // if (isInExistingPinEditMode) {
    //
    //
    //   // set data in Redux
    //   dispatch(setIsInExistingPinEditMode(true));
    // }

    //  _________________________________________________________________________________________
    if (isTouchDevice && Object.keys(ripspotData.pins).length < 1) {
      setSelectPinIsOpen(true);
    }
    if (openModal) {
      handleOpenRipspotModal();
      navigate(MAP_CR_DETAILS_ROUTE);
    }

    //  _________________________________________________________________________________________
    // reset anything edit mode related that has value (editPinId / editPinCoords)

    if (editModePinId || (newPin && isInExistingPinEditMode)) {
      // if user was currently editing a pin, reset editModePinId
      setEditModePinId('');
      setEditPinsLastCoords([]);
      setNewPin('');
    }
  };
  // * **************************************************************
  // *
  // * End Continue
  // *
  // * **************************************************************
  useEffect(() => {
    if (
      ripspotData.isInExistingPinEditMode &&
      ripspotData.tempData &&
      ripspotData.editInProgress &&
      pins.length > 0
    ) {
      // Aaron:: Not sure when this should be called.
      // updateRipspotHandler({
      //   isNewRipspot: ripspotData.isInNewRipspotMode, // done
      //   dispatch, // done
      //   ripspotData: ripspotData.tempData, // done
      //   user, // done
      //   ripspots: ripspotData, // done
      // }).then(data => {});
    }
  }, [pins]);
  //
  //
  //
  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  //
  //
  //

  // * **************************************************************
  // *
  // * handleMapClick
  // *
  // * Ripspot Map Click
  // *     - Opens a pin modal and
  // *      -sets the current coords
  // * Action 2
  // *
  // *
  // * @param {*} _Map
  // * @param {*} Point
  // * @returns
  // * **************************************************************
  const handleMapClick = (_Map, Point, _searchCoords) => {
    if (Point && Point.originalEvent.defaultPrevented) {
      Point.originalEvent.preventDefault();
      return;
    }
    if (isTouchDevice) return;
    let coords = [];
    if (_searchCoords) {
      coords = _searchCoords;
    } else {
      //
      //
      const { lngLat, point, target } = Point;

      const { lng, lat } = lngLat;
      coords = [lng, lat];
    }

    // allow clicks to see locations for now, short circuit if not editing
    if (!editInProgress) return null;

    // set pin corrds regardless of what step is being taken

    setNewPinCoords(coords);

    /**
     *
     * Edit in progresss!!
     *
     *  */

    const pinsKeys = Object.keys(ripspotData.pins);
    const nextIndex = pinsKeys.length; // this is how we know if it is first pin or not

    if (isFirstPin) {
      // *
      // *
      // * FIRST!!!! NEW ADDITONAL PINS
      // *
      // *
      //
      //
      // This needs to drop a newPin only and force
      // user to drag to final location and click "Done"
      // to continue to Bio modal
      //

      // if (isTouchDevice) {
      //   // if touch device, we need to use the current center of the map to place the pin the correct location
      //   if (mapRef.current) {
      //     // get map coords
      //     const map = mapRef.current;
      //     const center = map.getCenter();
      //     coords = [center.lng, center.lat];

      //   }
      // }

      if (newPin && newPinCoords && newPinCoords.length > 0) {
        // current pin being dragged can be moved via map clicks in other locations
        // setNewPinCoords(coords);
        // panToNewPinLocation(coords);
      } else {
        // setNewPinCoords(coords);
        //
        // Step 1 of actual UX...select pin style
        //
        // first click on map, open select pin modal
        //
        setSelectPinIsOpen(true);
      }
    } else if (editModePinId && !isFirstPin) {
      // current pin being dragged can be moved via map clicks in other locations
      // setNewPinCoords(coords);
      // panToNewPinLocation(coords);
      dispatch(updateRipspotPin(editModePinId, coords));
    } else if (!editModePinId) {
      // *
      // *
      // * NEW ADDITONAL PINS
      // *
      // *
      const newRipspotPin = {
        id: uuid(), // temp-id
        pinName: currentPinName,
        pinCoords: coords,
        pinIndex: nextIndex,
        isAdditional: true,
        pinLabel: '',
        pinDescription: '',
        pinLabel: '',
      };

      setNewPin(newRipspotPin);
      setCurrentPinName(currentPinName);
      setEditModePinId(newRipspotPin.id);

      // setNewPinCoords(mapCoords);
      // const panToArgs = { coords: mapCoords };
      //
      //
      // panTo(panToArgs);
      // , zoom: MAX_ZOOM_IN  // zoom all the way in

      dispatch(addRipspotPin(newRipspotPin));
    }
  };

  const beginTouchPinPlacement = additionalPin => {
    const map = mapRef.current;
    const center = map.getCenter();
    const defaultCoords = [center.lng, center.lat];

    const pinsKeys = Object.keys(ripspotData.pins);
    const nextIndex = pinsKeys.length;

    const defaultRipspotPin = {
      id: uuid(), // temp-id
      pinName: currentPinName,
      pinCoords: defaultCoords,
      pinIndex: nextIndex,
      pinLabel: '',
      pinDescription: '',
      isAdditional: false,
    };

    setNewPin(defaultRipspotPin);

    setNewPinCoords(defaultCoords);

    dispatch(addRipspotPin(defaultRipspotPin));
    setEditModePinId(defaultRipspotPin.id);

    if (!additionalPin) {
      setCurrentPinName('default-marker');
      dispatch(updateRipspotPinsName('default-marker'));
    }
  };

  // * **************************************************************

  // ___________________________________________________________________________
  //
  //
  //  select style modal
  //
  // ___________________________________________________________________________

  /**
   * Handles user pin selection from pin modal
   *
   *
   * @param {string} pinName
   */
  const onModalConfirmPin = pinName => {
    const hasPins = Object.keys(ripspotData.pins).length > 0;

    if (hasPins && hasEditChanges) {
      dispatch(updateRipspotPinsName(pinName));
      // this is needed for new pins being placed
      setCurrentPinName(pinName);
      // close selectPin modal
      closePinModal();
    } else {
      // this is a New pin!!!!
      // close selectPin modal
      closePinModal();
      // let UI know there are current edit changes
      setHasEditChanges(true);
      setCurrentPinName(pinName);

      setNewPin({
        id: uuid(), // temp-id // TODO in future this might have the actual ID if we create the ripspot immediately
        pinName,
      });
      // setNewPinCoords(mapCoords);
      // panTo({ coords: mapCoords });
      // , zoom: MAX_ZOOM_IN // zoom all the way in

      let pinCoords = newPinCoords;
      // currently saving a new pin
      const nextIndex = Object.keys(ripspotData.pins).length;
      const newRipspotPin = {
        id: uuid(), // temp-id
        pinName: currentPinName,
        pinCoords,
        pinIndex: nextIndex,
        pinLabel: '',
        pinDescription: '',
        pinLabel: '',
      };

      dispatch(addRipspotPin(newRipspotPin));
      setEditModePinId(newRipspotPin.id);

      // Resetting pin coords to avoid duplicates
      // setNewPinCoords([]);

      dispatch(updateRipspotPinsName(pinName));
    }
  };

  // * **************************************************************
  // *
  // *
  // * START Handle cancel ripspot or pins
  // *
  // * **************************************************************

  /**
   * Cancels changes to a pins coords (position) OR Cancelation of entire ripspot
   *
   * @param {*} evt
   */
  const handleCancel = evt => {
    evt.preventDefault();
    if (editModePinId && editPinsLastCoords.length > 0) {
      // if currently editing individual pin location, reset it to last saved loc
      // reset pin to last saved coords
      dispatch(updateRipspotPin(editModePinId, editPinsLastCoords));
      // reset current edit pin id
      setEditModePinId(''); // removes toggled opacity from all pins
    } else {
      // in general edit modality, clear state
      // seteditInProgress(false);

      dispatch(resetRipspotPins());

      dispatch(resetTempRipspot());
      // setCurrentPinName(''); // clear newly placed pin if exists

      setNewPin(''); // clear newly placed pin if exists
      setCurrentPinName(''); // clears custom pin if one was chosen
      setEditModePinId('');
      dispatch(setIsInNewRipspotMode(false));
      dispatch(removeCurrentRipspot());
      dispatch(setViewSingleRipspot(false));
      shouldShowRandomPins({
        dispatch,
        setPublicPins,
        limit: 100,
        query: {}, // optional
      });

      dispatch(setEditInProgress(false));

      if (editInProgress) {
      }
    }
    navigate(MAP_SEARCH_ROUTE);
  };

  const handleCancelAdditionalPin = id => {
    setNewPin(''); // clear newly placed pin if exists
    setEditModePinId('');
    dispatch(removeRipspotPin(id));

    setNewPinCoords(US);
  };

  // * **************************************************************
  // *
  // *
  // * END Handle cancel ripspot ^^^
  // *
  // * **************************************************************

  //
  // state boolean for UI changes
  //
  const mapBooleanProps = {
    isTouchDevice,
    isHoverDevice,
    isFirstPin,
    inViewMode,
    selectPinIsOpen,
    // important!
    editInProgress, // new redux global var we are passing as old local editInProgress
  };

  //
  // functions
  //
  const mapFnProps = {
    panTo,
    zoomTo,
    dispatch,
    setMapState,
    handleContinue,
    handleCancel,
    handleCancelAdditionalPin,
    setNewPinCoords,
    setEditModePinId,
    setEditPinsLastCoords,
    setSelectPinIsOpen,
    setHasEditChanges,
    handleMapClick, // * NEW
    setCurrentOpenPopupId,
  };

  const mapOtherProps = {
    mapRef,
  };

  const mapInlineFnProps = {
    // New Props \/
    onConfirmPinLocationClick: (evt, newSearchPinCoords) => {
      //
      // newSearchPinCoords should === searchPinCoords
      //
      // TODO: Set a pin at this loocation

      // 1. call ripspot creation handler --> same handler when we click the map
      // so pass in mnull values for map instance and map Point and explictly pass coords
      // as third arg
      handleMapClick(null, null, newSearchPinCoords);
      // 2. clear Location results we just clicked 'confirm' on
      setSearch('');
      setSearchItem(null);
      setSearchPinCoords([]);
    },
    // New Props /\
    onEditRipspotClick: ripspotId => evt => {
      // handles clicks from Marker popup
      if (ripspotData.currentRipspot.id === ripspotId) {
        dispatch(hydrateTempData(ripspotData.currentRipspot));
      }
      dispatch(setEditInProgress(true));
      // setCurrentEditRipspot(ripspotData[ripspotId]);
    },
    onViewRipspotClick: ripspotId => evt => {
      // handles clicks from Marker popup
      const routeOpts = {
        state: { ripspotId, viewDefaultRipspots: true },
      };

      dispatch(setEditInProgress(false));
      navigate(`/ripspot/${ripspotId}`, routeOpts);
    },
    handleOnDeleteMarker: markerId => {
      dispatch(removeRipspotPin(markerId));
    },
  };

  const mapProps = {
    ...mapBooleanProps, // bools
    ...mapOtherProps, // ref
    ...mapFnProps, // functions
    ...mapInlineFnProps, // functions built in the object, "inline"
    // ripspots: {
    //   // TODO: reconsider ripspot data scheme passed into BaseMap and redux
    //   ...ripspotData,
    //   ripspots: publicRipspots,
    //   publicRipspotPins,
    // },
    isInExistingPinEditMode: ripspotData.isInExistingPinEditMode,
    mapState,
    newPin,
    newPinCoords,
    currentPinName,
    editModePinId,
    currentOpenPopupId,
    user,
  };

  //
  //
  //
  //

  // const showSearchPin = searchPinCoords && searchPinCoords.length > 0;
  const controlAndSearchOpen =
    mapStyleReady && !selectPinIsOpen && !ripspotModal.isOpen;

  const isMobile = isTouchDevice; // dimensions.width < Theme.breakpoints.mobile;

  const isFullTakeover = !!(
    isTouchDevice &&
    (newPin || editModePinId || viewSingleRipspot)
  );

  // on touch devices, map is shorter to avoid search engine's search box overlay
  let height;
  let mobileControlsHeight;
  if (isFullTakeover) {
    mobileControlsHeight = HEADER_HEIGHT + CONTROLS_HEIGHT;
    height = mapState.viewport.height - mobileControlsHeight;
  } else if (isMobile || isTouchDevice) {
    height = mapState.viewport.height - HEADER_HEIGHT - CONTROLS_HEIGHT;
    mobileControlsHeight = HEADER_HEIGHT + CONTROLS_HEIGHT;
  } else {
    height = mapState.viewport.height - HEADER_HEIGHT;
  }

  const isSatellite = mapState.style === satelliteStyle;

  const handleToggleMapStyle = evt => {
    evt.stopPropagation();
    const newStyle = isSatellite ? streetStyle : satelliteStyle;
    const center = mapRef.current.getCenter();
    const newMapState = {
      viewport: {
        ...mapState.viewport,
        longitude: center.lng,
        latitude: center.lat,
      },
      style: newStyle,
    };

    setMapState(newMapState);
  };

  return (
    <>
      <GlobalStyle useCrosshair={editInProgress} isMobile={isMobile} />
      {/* <GlobalStyle useCrosshair={editInProgress && !isFullPins} /> */}
      <AdditionalPinModal
        positive
        customPrompt="Would you like to drop another pin?"
        denyPrompt="No, Continue to Ripspot creation"
        confirmPrompt="Please..." // 'Yes Please'  a shorter string for button text
        onConfirm={() => {
          // return to map to click another spot to drop a pin
          toggleAdditionalPinModal(false);
          beginTouchPinPlacement(true);
        }}
        onDeny={() => {
          toggleAdditionalPinModal(false);
          // open up
          const evt = {
            preventDefault: () => {},
            stopPropagation: () => {},
            target: {},
          };
          handleContinue()(evt); // ()(evt) ==> should be event but it still runs without, evt not callled
        }}
        isOpen={additionalPinModal.isOpen}
        onRequestClose={() => toggleAdditionalPinModal(false)}
      />
      <Container myHeight={height} isFullTakeover={isFullTakeover}>
        <TopMapNav
          isSatellite={isSatellite}
          editInProgress={editInProgress}
          handleToggleMapStyle={handleToggleMapStyle}
          isMobile={isMobile}
          viewSingleRipspot={viewSingleRipspot}
          isInExistingPinEditMode={setIsInExistingPinEditMode}
          isFullTakeover={isFullTakeover}
        />
        {/* Root of MAP   \/ \/ \/ */}
        <BaseMap
          onStyleLoad={() => setMapStyleReady(true)}
          isFullTakeover={isFullTakeover}
          searchPinCoords={searchPinCoords}
          searchItem={searchItem}
          publicRipspotPins={publicRipspotPins}
          isInExistingPinEditMode={isInExistingPinEditMode}
          isViewingSingleRipspot={isViewingSingleRipspot}
          {...mapProps}
        />
        {/* End of root of MAP  /\ /\ /\ */}

        <SearchOverlay
          isOpen={controlAndSearchOpen} // container is visible
          initSearch={search}
          editInProgress={editInProgress}
          closeMe={() => setSearchIsOpen(false)}
          onClearSearch={() => {
            setSearchPinCoords([]);
            setSearch('');

            if (!editInProgress) {
              // remove the currentRipspot and pins in case we searched for it previously
              dispatch(removeCurrentRipspot());
              dispatch(setViewSingleRipspot(false));
            }
          }}
          onSubmit={newSearch => setSearch(newSearch)}
          onSearchSelect={(item, type) => {
            switch (type) {
              case 'ripspot':
                // two steps
                // 1) save the ripspot in redux
                dispatch(hydrateRipspot(item));
                // 2) set map bounds with the new pins
                const newRipspotPins = item.pins;
                // sets map to bound pins so that all our visible
                setMapBounds(newRipspotPins);

                if (!editInProgress) {
                  // 3. clear Location results in case we have some
                  setSearchItem(null);
                  setSearchPinCoords([]);
                  dispatch(setViewSingleRipspot(true));
                }
                break;
              case 'location':
                setSearchPinCoords(item.center);
                setSearchItem(item);
                if (!editInProgress) {
                  // remove the currentRipspot and pins in case we searched for it previously
                  dispatch(removeCurrentRipspot());
                  dispatch(setViewSingleRipspot(false));
                }
                const currentZoom = mapRef.current && mapRef.current.getZoom();
                const flyToProps = {
                  center: item.center,
                  zoom: currentZoom, // , zoom: 12.5
                };
                const panToProps = {
                  coords: item.center,
                  zoom: currentZoom, // , zoom: 12.5
                };

                mapRef.current && mapRef.current.flyTo(flyToProps);
                mapRef.current && panTo(panToProps);
                break;
              default:
            }
          }}
        />
        <OnBoardingModal
          isOpen={onBoardingModal.isOpen}
          onRequestClose={() => {
            // close on boarding modal
            dispatch(closeOnBoardingModal());
          }}
        />
        <SelectPinModal
          isOpen={selectPinIsOpen}
          onRequestClose={() => setSelectPinIsOpen(false)}
          currentPinName={
            (newPin && currentPinName) || // new pin
            (editModePinId && ripspotData.pins[editModePinId].pinName) ||
            'default-marker' // editing existing pin
          }
          onConfirm={onModalConfirmPin}
        />
        {/* New Ripspot Memorial Modal to open when we click on pin */}
        <RipspotMemorialModal />
        {/* 
          New Ripspot Memorial Modal ^^^ 
          It handles all its own redux state
        */}
      </Container>
      <Controls
        isOpen={controlAndSearchOpen}
        openPinModal={openPinModal}
        newPin={newPin}
        setNewPin={setNewPin}
        handleContinue={handleContinue}
        ripspotData={ripspotData}
        editModePinId={editModePinId}
        handleCancel={handleCancel}
        handleCreateNewRipspot={handleCreateNewRipspot}
        isFullTakeover={isFullTakeover}
        isMobile={isMobile && isTouchDevice}
        height={mobileControlsHeight}
        // handleCloseEditPin={handleContinue(false)}
        onConfirmEditPinClick={handleContinue(false, false)}
        setCurrentOpenPopupId={setCurrentOpenPopupId}
        setCurrentPinName={setCurrentPinName}
        currentPinName={currentPinName}
        onBoardingModalIsOpen={onBoardingModal.isOpen}
        beginTouchPinPlacement={beginTouchPinPlacement}
      />
    </>
  );
};

ExploreMap.propTypes = {
  isTouchDevice: PropTypes.bool.isRequired,
  isHoverDevice: PropTypes.bool.isRequired,
  user: PropTypes.any,
  ripspotModal: PropTypes.shape({
    isOpen: PropTypes.bool,
  }),
  onBoardingModal: PropTypes.shape({
    isOpen: PropTypes.bool,
  }),
  additionalPinModal: PropTypes.shape({
    isOpen: PropTypes.bool,
  }),
  location: PropTypes.shape({
    pathname: PropTypes.string,
    state: PropTypes.shape({
      isEditMode: PropTypes.bool,
      isInExistingPinEditMode: PropTypes.bool,
      shouldBoundPins: PropTypes.bool,
      createNewRipspot: PropTypes.bool,
      viewDefaultRipspots: PropTypes.bool,
      viewRipspot: PropTypes.bool,
    }),
  }),
  ripspotData: PropTypes.shape({
    tempData: PropTypes.any,
    ripspots: PropTypes.any,
    currentRipspot: PropTypes.any,
    publicRipspotPins: PropTypes.any,
    pins: PropTypes.any,
    editInProgress: PropTypes.bool,
    isInNewRipspotMode: PropTypes.bool,
  }),
  dispatch: PropTypes.func.isRequired,
  dimensions: PropTypes.any.isRequired,
};

const mapStateToProps = ({ display, modals, user, ripspots }) => ({
  ripspotModal: modals.ripspotModal,
  onBoardingModal: modals.onBoardingModal,
  additionalPinModal: modals.additionalPinModal,
  user,
  ripspotData: ripspots,
  isViewingSingleRipspot: ripspots.viewSingleRipspot,
  dimensions: display.dimensions,
  isTouchDevice: display.isTouchDevice,
  isHoverDevice: display.isHoverDevice,
  boundRipspotPins: ripspots.boundRipspotPins,
});
const withConnect = connect(mapStateToProps);
export default compose(withConnect)(ExploreMap);

const Container = styled.div`
  position: relative;
  background-color: ${({ theme }) => theme.colors.Primary};

  ${({ isFullTakeover }) =>
    isFullTakeover &&
    css`
      ${'' /* position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0; */}
    `}
  ${({ myHeight }) => css`
    height: ${myHeight}px;
  `}
`;
